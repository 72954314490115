import { template as template_16e70a6fdfcc4462b9ba99d640a1c920 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import FidgetLoadingIndicatorComponent from '@blakeelearning/fidget/components/fidget/loading-indicator';
import RouteTemplate from 'ember-route-template';
import { t } from 'ember-intl';
export const ManageSchoolsLoadingRouteTemplate: TOC<unknown> = template_16e70a6fdfcc4462b9ba99d640a1c920(`
  <FidgetLoadingIndicatorComponent @show={{true}} @overlay={{false}} @loadingText={{t "loading"}} @centered={{true}} />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ManageSchoolsLoadingRouteTemplate);
