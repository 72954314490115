import { template as template_7719c574af8747bdb0e171d980473fe9 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import type ReLexileDetailsRoute from 'district-ui-client/reporting/ui-scope/re/lexile-details/route';
import { not } from 'ember-truth-helpers';
import { ChartsLexileDetailsAverages } from 'district-ui-client/components/charts/lexile-details/averages/component';
import { t } from 'ember-intl';
import fullGradeName from 'district-ui-client/helpers/full-grade-name';
import RouteTemplate from 'ember-route-template';
interface Signature {
    model: ModelFor<ReLexileDetailsRoute>;
}
export const ReLexileDetailsRouteTemplate: TOC<Signature> = template_7719c574af8747bdb0e171d980473fe9(`
  <DetailedPageWrapper @empty={{not @model.lexileDetailsAverages}}>
    {{#each @model.lexileDetailsAverages.lexile_details_averages as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsLexileDetailsAverages
          class="h-full"
          @data={{gradeData}}
          @grade={{fullGradeName gradeData.grade_position}}
          @product={{@model.product}}
          @tooltip={{t "reporting.tooltips.lexileDetailsAverages"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ReLexileDetailsRouteTemplate);
