import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'
import type StudentEventsService from 'district-ui-client/services/student-events'
import type UiScopeRoute from 'district-ui-client/reporting/ui-scope/route'
import type { StatsScope } from 'district-ui-client/utils/student-events/stats-scope'
import type { NormalizedInterval } from 'date-fns'
import type { TimeOnTaskHistoryData } from 'district-ui-client/components/reporting/time-on-task/table'

interface TimeOnTaskUsageResponseData {
  time_on_task_summary: {
    student_count: number
    total_seconds: number
    average_seconds_per_student: Nullable<number>
    session_count: number
    average_sessions_per_student: Nullable<number>
    average_seconds_per_student_per_week: Nullable<number>
    average_seconds_per_session: Nullable<number>
  }
  time_on_task_by_month: {
    month: string
    seconds: Nullable<number>
  }[]
}

interface StatsResponse {
  timeOnTaskUsage: Nullable<TimeOnTaskUsageResponseData>
  timeOnTaskHistory: Nullable<TimeOnTaskHistoryData>
}

export default class TimeOnTaskRoute extends Route {
  @service studentEvents!: StudentEventsService

  async model() {
    const product: LegacyProductRe = 're'
    const {
      uiScope: scope,
      statsScope,
      period,
      scopedData,
    } = this.modelFor('reporting.ui-scope') as ModelFor<UiScopeRoute>

    const stats = await this.makeStatsRequest(product, statsScope, period)
    return {
      product,
      scope,
      period,
      scopedData,
      ...stats,
    }
  }

  makeStatsRequest(product: string, scope: StatsScope, period: NormalizedInterval): Promise<StatsResponse> {
    const { studentEvents } = this

    return RSVP.hash({
      timeOnTaskHistory: studentEvents.timeOnTaskHistory(product, scope, period),
      timeOnTaskUsage: studentEvents.timeOnTaskUsage(product, scope, period),
    })
  }
}
