import { template as template_87953daa8ceb49b9813a00e1117fc39c } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
export const CleverPage: TOC<unknown> = template_87953daa8ceb49b9813a00e1117fc39c(`
  <div class="mx-8 my-10">
    {{outlet}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(CleverPage);
