import { classNames } from '@ember-decorators/component'
import { service } from '@ember/service'
import { readOnly, notEmpty, filterBy, equal, and } from '@ember/object/computed'
import { isEmpty } from '@ember/utils'
import Component from '@ember/component'
import { set, action, computed } from '@ember/object'

/**
 * Manages the creating, and editing of studnet matches
 * @class MatchStudentsTableComponent
 * @property {string} activeTab - current active tab
 * @property {Array<StudentModel>} blakeStudents - existing students to be matched against
 * @property {Array<CleverStudentModel>} studentMatchRows - clever students ready to be matched
 * @property {TaskInstance} bulkCreateStudent - task for performing the bulk creation of students
 */
@classNames('__clever-ui__match-students-table__e4d11')
export default class MatchStudentsTable extends Component {
  filterPlaceholder = 'search'

  showCreateUsersModal = false

  @service intl

  @service gradeSets

  @computed('activeTab')
  get matchColumnText() {
    const { activeTab } = this
    const { intl } = this
    return intl.t(`clever.${activeTab}ColumnText`)
  }

  subscriptionType = null

  get productName() {
    return this.intl.t(`subscriptions.${this.subscriptionType}`)
  }

  @computed('blakeStudents.@each.{cleverStudentMatch,sisId}')
  get blakeStudentsOptions() {
    const blakeStudents = this.blakeStudents || []

    const unmatchedBlakeStudents = blakeStudents.filter((student) => {
      return !student.belongsTo('cleverStudentMatch').value() || isEmpty(student.get('sisId'))
    })

    return unmatchedBlakeStudents
      .map((student) => {
        const { gradePosition } = student
        let gradeAffix = ''
        if (gradePosition) gradeAffix = `, ${this.gradeSets.findByPosition(gradePosition)?.fullName}`
        return {
          text: student.fullName,
          value: student.id,
          uid: student.id,
          grade: gradeAffix,
        }
      })
      .sort((a, b) => a.text.localeCompare(b.text))
  }

  @computed('studentMatchRows.@each.matched')
  get records() {
    /* this is here to force the records property to become dirty
       so that the item count in the tabs updates properly
       TODO: should investigate and refactor this properly
    */
    return this.studentMatchRows.map((item) => item)
  }

  get isTaskLoading() {
    return this.bulkCreateStudent?.isRunning
  }

  @filterBy('records', 'matched', false)
  unmatchedRecords

  @equal('activeTab', 'unmatched')
  isUnmatchedTab

  @equal('activeTab', 'matched')
  isMatchedTab

  @notEmpty('unmatchedRecords')
  hasUnmatchedRecords

  @and('isUnmatchedTab', 'hasUnmatchedRecords')
  showCreateNew

  @filterBy('unmatchedRecords', 'isCreateBlakeStudentChecked', true)
  unmatchedChecked

  @readOnly('unmatchedChecked.length')
  unmatchedCheckedCount

  @action
  bulkCreateAction() {
    const checkedRecords = this.unmatchedChecked
    const onComplete = () => {
      // Keep the modal open for a moment, to allow for button/success animation, before closing it
      if (this && !this.isDestroying && !this.isDestroyed) {
        set(this, 'showCreateUsersModal', false)
      }
    }
    this.bulkCreateStudent.perform(checkedRecords, { onComplete, completeDelay: 1000 })
  }

  @action
  onSelectRow(cleverRecord, selected) {
    set(cleverRecord, 'isCreateBlakeStudentChecked', selected)
  }

  @action
  showCreateUserModal() {
    set(this, 'showCreateUsersModal', true)
  }

  @action
  closeCreateUserModal() {
    if (!this.bulkCreateStudent?.isRunning) {
      set(this, 'showCreateUsersModal', false)
    }
  }

  @action
  onTextInput(value) {
    set(this, 'config.filtering.filterFieldValue', value)
  }
}
