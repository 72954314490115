import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'
import type StudentEventsService from 'district-ui-client/services/student-events'
import type UiScopeRoute from 'district-ui-client/reporting/ui-scope/route'
import type { StatsScope } from 'district-ui-client/utils/student-events/stats-scope'
import type { NormalizedInterval } from 'date-fns'
import type { StoriesWrittenTotalsData } from 'district-ui-client/components/charts/stories-written/totals/component'

interface StatsResponse {
  storiesWrittenTotals: Nullable<{ stories_written_totals: StoriesWrittenTotalsData[] }>
}

export default class ReStoriesWrittenRoute extends Route {
  @service studentEvents!: StudentEventsService

  async model() {
    const product: LegacyProductRe = 're'
    const {
      uiScope: scope,
      statsScope,
      period,
      scopedData,
    } = this.modelFor('reporting.ui-scope') as ModelFor<UiScopeRoute>

    const stats = await this.makeStatsRequest(product, statsScope, period)
    return {
      product,
      scope,
      period,
      scopedData,
      ...stats,
    }
  }

  makeStatsRequest(product: string, scope: StatsScope, period: NormalizedInterval): Promise<StatsResponse> {
    const { studentEvents } = this

    return RSVP.hash({
      storiesWrittenTotals: studentEvents.storiesWrittenTotals(product, scope, period),
    })
  }
}
