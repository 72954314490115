import { template as template_2f24a793b8a74c70a9488b3757cdce0e } from "@ember/template-compiler";
import Component from '@glimmer/component';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import colors from '@blakeelearning/blake-colours/colours';
import { LeftSplitChart } from 'district-ui-client/components/left-split-chart';
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box';
import { SeriesColumn } from 'district-ui-client/components/primitives/series-column/component';
import type { ReportingProductSlug } from 'district-ui-client/domain/product';
import { eq } from 'ember-truth-helpers';
import type { SeriesColumnOptions } from 'highcharts';
const computeGoldenEggs = (gold: number, silver: number, bronze: number)=>{
    const totalSilver = gold * 3 + silver;
    const totalBronze = totalSilver * 5 + bronze;
    return totalBronze * 1000;
};
/**
 * Puts together all the pieces for the awards earned totals chart.
 *
 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   summary: [
 *     { category: 'gold', count: 90 },
 *     { category: 'silver', count: 123 },
 *     { category: 'bronze', count: 250 },
 *   ],
 *   months: [
 *     {
 *       gold: 20,
 *       silver: 23,
 *       bronze: 125,
 *       month: '2016-07',
 *     },
 *     {
 *       gold: 70,
 *       silver: 100,
 *       bronze: 125,
 *       month: '2016-08',
 *     },
 *   ],
 * ```
 */ export interface AwardsEarnedTrophiesTotalData {
    grade_position: number;
    summary: {
        category: string;
        count: number;
    }[];
    months: {
        gold: number;
        silver: number;
        bronze: number;
        month: string;
    }[];
}
interface Signature {
    Args: {
        data?: AwardsEarnedTrophiesTotalData;
        product: ReportingProductSlug;
        grade?: string;
        title?: string;
        yAxisTitle?: string;
    };
    Element: HTMLDivElement;
}
export class ChartsAwardsEarnedTrophiesTotals extends Component<Signature> {
    /**
   * Computed list of summary statistics, extracted from the input data.
   * Display total gold, silver and bronze counts.
   */ get summaryStats() {
        const summaryData = this.args.data?.summary ?? [];
        const gold = summaryData.find((d)=>d.category === 'gold')?.count ?? 0;
        const silver = summaryData.find((d)=>d.category === 'silver')?.count ?? 0;
        const bronze = summaryData.find((d)=>d.category === 'bronze')?.count ?? 0;
        const total = summaryData.reduce((sum, d)=>sum + d.count, 0);
        return [
            {
                label: 'Gold trophy',
                value: formatNumber(gold, 0)
            },
            {
                label: 'Silver medallion',
                value: formatNumber(silver, 0)
            },
            {
                label: 'Bronze medal',
                value: formatNumber(bronze, 0)
            },
            {
                label: 'Total',
                value: formatNumber(total, 0)
            }
        ];
    }
    /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */ get columns(): SeriesColumnOptions[] {
        const goldenEggs = this.args.data?.months.map(({ gold, silver, bronze })=>computeGoldenEggs(gold, silver, bronze));
        return [
            {
                name: 'Golden Eggs',
                data: goldenEggs,
                color: colors.juicyOrange300,
                type: 'column'
            }
        ];
    }
    /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */ get categories() {
        return this.args.data?.months?.map((m)=>convertDateFormat(m.month)) ?? [];
    }
    static{
        template_2f24a793b8a74c70a9488b3757cdce0e(`
    <div ...attributes>
      <LeftSplitChart>
        <:left>
          <SummaryBox
            class="h-full"
            @grade={{@grade}}
            @stats={{this.summaryStats}}
            @colorScheme={{if (eq @product "maths") SummaryBoxColorScheme.DarkGreen SummaryBoxColorScheme.LightBlue}}
          />
        </:left>
        <:right>
          <SeriesColumn
            @title={{@title}}
            @data={{this.columns}}
            @categories={{this.categories}}
            @stackingStyle="normal"
            @yAxisLabel={{@yAxisTitle}}
            @yAxisReversedStacks={{false}}
            @chartSpacing={{Array 10 10 10 10}}
          />
        </:right>
      </LeftSplitChart>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChartsAwardsEarnedTrophiesTotals;
