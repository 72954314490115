import { template as template_26e6b554b3454a1da72512e02d9ee556 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { LinkTo } from '@ember/routing';
import { ThemeKeys } from 'district-ui-client/utils/themes';
import { eq } from 'ember-truth-helpers';
import { hash } from '@ember/helper';
interface Signature {
    Args: {
        themeKey: ThemeKeys;
        options: {
            route: string;
            query?: Record<string, unknown>;
            title: string;
            id: string;
        }[];
    };
    Element: HTMLDivElement;
}
export const CleverUiTabs: TOC<Signature> = template_26e6b554b3454a1da72512e02d9ee556(`
  <div class="relative mt-10 flex" ...attributes>
    {{#each @options as |option|}}
      <LinkTo
        class="{{if
            (eq @themeKey ThemeKeys.green)
            'selected:bg-ms-green-100 selected:hover:bg-ms-green-100 selected:focus:bg-ms-green-100'
            'selected:bg-oceany-blue-100 selected:hover:bg-oceany-blue-100 selected:focus:bg-oceany-blue-100'
          }}
          border-r-dusty-black-50 bg-dusty-black-100 hover:bg-dusty-black-200 focus:bg-dusty-black-200 selected:font-bold inline-block border-r-2 px-8 py-4 text-sm text-inherit transition-colors duration-500"
        @route={{option.route}}
        @query={{if option.query option.query (hash)}}
        data-test-tab={{option.id}}
      >
        {{option.title}}
      </LinkTo>
    {{/each}}

  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default CleverUiTabs;
