import { template as template_80ebd6c4454145c998fa2b8a9500e0c1 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLDivElement;
}
export const NotificationDot: TOC<Signature> = template_80ebd6c4454145c998fa2b8a9500e0c1(`
  <div class="inline-block h-1.5 w-1.5 shrink-0 grow-0 rounded-full bg-pink-300" ...attributes></div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
