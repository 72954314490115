import { template as template_cea987f83fd0400c9fa89b52887f1243 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { AlertType, InlineAlert } from 'district-ui-client/components/inline-alert';
import type StandardsSet from 'district-ui-client/models/standards-set';
import { t } from 'ember-intl';
interface Signature {
    Args: {
        currentStandardsSet: StandardsSet;
        reportStandardsSetId?: Nullable<string>;
    };
}
export class StandardsSetUpdatedAlert extends Component<Signature> {
    /* Only show when the current standards does not match the standards that the report was generated with.
   * Explicitly exclude undefined from showing the alert, this may be the case during loading of a report.
   */ get showAlert() {
        return (this.args.reportStandardsSetId !== undefined && this.args.reportStandardsSetId !== this.args.currentStandardsSet.id);
    }
    static{
        template_cea987f83fd0400c9fa89b52887f1243(`
    {{#if this.showAlert}}
      <InlineAlert data-test-report-standards-updated-alert @type={{AlertType.Warning}}>
        <div class="space-y-2">
          <h2 class="m-0 text-sm font-medium">{{t "stateAcademicStandards.updatedAlert.title"}}</h2>
          <p class="m-0 text-sm">
            <span>{{t
                "stateAcademicStandards.updatedAlert.currentValue"
                htmlSafe=true
                name=@currentStandardsSet.name
              }}</span>
            <span>{{t "stateAcademicStandards.updatedAlert.leadTimeWarning" htmlSafe=true}}</span><br />
            <span>{{t "stateAcademicStandards.updatedAlert.oldDataWarning"}}</span>
            <span></span>
          </p>
        </div>
      </InlineAlert>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
