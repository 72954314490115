import { template as template_b32c4e4abee14ebea8c1a3ffbab57924 } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
export default RouteTemplate(template_b32c4e4abee14ebea8c1a3ffbab57924(`
    <div class="flex-shrink flex-grow overflow-y-auto px-8 py-10">
      {{outlet}}
    </div>
  `, {
    eval () {
        return eval(arguments[0]);
    }
}));
