import Model, { attr, belongsTo } from '@ember-data/model'
import type CleverDistrictMatch from 'district-ui-client/models/clever/clever-district-match'
import type CleverSchool from 'district-ui-client/models/clever/clever-school'
import type GravitySchool from 'district-ui-client/models/clever/school'

/**
 * Model class for clever sisId and district id.
 *
 * @class CleverSchoolMatchModel
 * @property {string} sisId - unique id which relates to a clever record fo this type
 */
export default class CleverSchoolMatch extends Model {
  @attr('string') sisId!: string

  @attr('string') cleverId!: string

  @belongsTo('clever/school', { async: false, inverse: 'cleverSchoolMatch' })
  school?: GravitySchool

  @belongsTo('clever/clever-school', { async: false, inverse: 'cleverSchoolMatch' })
  cleverSchool?: CleverSchool

  // Provided when creating a school match record
  @belongsTo('clever/clever-district-match', { async: false, inverse: null })
  cleverDistrictMatch?: CleverDistrictMatch
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'clever/clever-school-match': CleverSchoolMatch
  }
}
