import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'
import type StudentEventsService from 'district-ui-client/services/student-events'
import type UiScopeRoute from 'district-ui-client/reporting/ui-scope/route'
import type { StatsScope } from 'district-ui-client/utils/student-events/stats-scope'
import type { NormalizedInterval } from 'date-fns'
import { type TotalUsageOverTimeData } from 'district-ui-client/components/charts/dashboard/total-usage-over-time/component'
import { type LexileGrowthData } from 'district-ui-client/components/charts/dashboard/lexile-growth/component'
import { type CourseProgressData } from 'district-ui-client/components/charts/dashboard/course-progress/component'
import { type BooksReadByGenreData } from 'district-ui-client/components/charts/dashboard/books-read/by-genre/component'
import { type AwardsEarnedData } from 'district-ui-client/components/charts/dashboard/awards-earned/ribbons'
import { type AssessmentScoresData } from 'district-ui-client/components/charts/dashboard/assessment-scores/component'
import { type StoriesWrittenData } from 'district-ui-client/components/charts/dashboard/stories-written/component'
import { type WorkingAtGradeLevelData } from 'district-ui-client/components/charts/dashboard/working-at-grade-level/component'

interface StatsResponse {
  totalUsageOverTime: Nullable<TotalUsageOverTimeData>
  lexileGrowth: Nullable<LexileGrowthData>
  courseProgressByGrade: Nullable<CourseProgressData>
  spellingCourseProgress: Nullable<CourseProgressData>
  booksReadByGenre: Nullable<BooksReadByGenreData>
  awardsEarned: Nullable<AwardsEarnedData>
  assessmentScores: Nullable<AssessmentScoresData>
  workingAtGradeLevel: Nullable<WorkingAtGradeLevelData>
  storiesWritten: Nullable<StoriesWrittenData>
}

export class ReportingReDashboardRoute extends Route {
  @service studentEvents!: StudentEventsService

  async model() {
    const product: LegacyProductRe = 're'
    const {
      uiScope: scope,
      statsScope,
      period,
      scopedData,
    } = this.modelFor('reporting.ui-scope') as ModelFor<UiScopeRoute>

    const stats = await this.makeStatsRequest(product, statsScope, period)
    return {
      product,
      scope,
      period,
      scopedData,
      ...stats,
    }
  }

  makeStatsRequest(product: string, scope: StatsScope, period: NormalizedInterval): Promise<StatsResponse> {
    const { studentEvents } = this
    return RSVP.hash({
      totalUsageOverTime: studentEvents.totalUsageOverTime(product, scope, period),
      lexileGrowth: studentEvents.lexileGrowth(product, scope, period),
      courseProgressByGrade: studentEvents.courseProgressByGrade(product, 'reading', scope, period),
      spellingCourseProgress: studentEvents.courseProgressByGrade(product, 'spelling', scope, period),
      booksReadByGenre: studentEvents.booksReadByGenre(product, scope, period),
      awardsEarned: studentEvents.awardsEarned(product, scope, period),
      assessmentScores: studentEvents.assessmentScores(product, scope, period),
      workingAtGradeLevel: studentEvents.workingAtGradeLevel(product, scope, period),
      storiesWritten: studentEvents.storiesWrittenByGrade(product, scope, period),
    })
  }
}

export default ReportingReDashboardRoute
