import type { Log } from '@blakeelearning/log'
import type Store from '@ember-data/store'
import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'
import type Transition from '@ember/routing/transition'
import { service } from '@ember/service'
import type SubscriptionTypeRoute from 'district-ui-client/subscription-type/route'
import type SessionService from 'district-ui-client/services/session'

export default class CleverIdRoute extends Route {
  @service session!: SessionService

  @service store!: Store

  @service router!: RouterService

  @service log!: Log

  async model() {
    const { subscriptionType } = this.modelFor('subscription-type') as ModelFor<SubscriptionTypeRoute>
    const { store } = this

    const { id: districtId } = this.session.currentDistrict
    const blakeDistrict = await store.findRecord('district', districtId, {
      reload: true,
      include: 'clever-district-match',
    })

    return {
      subscriptionType,
      district: blakeDistrict,
      cleverDistrictMatch: blakeDistrict.cleverDistrictMatch,
    }
  }

  async afterModel(model: ModelFor<CleverIdRoute>, transition: Transition) {
    if (transition.to?.name === 'clever-id.initial-sync-in-progress') return

    const { router } = this
    const { cleverDistrictMatch, subscriptionType } = model

    const cleverDistrict = await cleverDistrictMatch?.loadCleverDistrict({ ignore404: true })

    // No clever-district was returned for the the match record, go back to start
    if (!cleverDistrict) {
      router.transitionTo('clever-id.start-initial-sync')
      return
    }

    /* clever-district record is available, now check the sync record */

    // If clever is not currently in progress (ie no sync record), it means we're either;
    // 1. at the beginning (nothing started),
    // 2. at the end (everything managed),
    // 3. in the intermission (one sub type managed, one sub type not started)
    if (!cleverDistrict.isCleverUndergoingSetup) {
      const isCurrentSubtypeCleverManaged = cleverDistrictMatch?.getCleverManagedByType(subscriptionType)

      // Ensure they can use the dash for their managed sub type (2 or 3)
      if (isCurrentSubtypeCleverManaged) {
        router.transitionTo('clever.dashboard')
        return
      } else {
        // Else, allow them to start the sync for this product (1 or 3)
        router.transitionTo('clever-id.start-initial-sync')
        return
      }
    }

    // If user needs to go finish clever matching for other sub type, take them to the page that tells them to do so.
    const finishMatchingOther = cleverDistrict.isCleverUndergoingSetupForOther(subscriptionType)
    if (finishMatchingOther) {
      router.transitionTo('clever-id.matching-in-progress')
      return
    }

    // Clever data still downloading, take them to the page with a loading spinner that polls the records
    if (cleverDistrict.isDataInitializing) {
      router.transitionTo('clever-id.initial-sync-in-progress')
      return
    }

    if (cleverDistrict.isDataInitialized) {
      router.transitionTo('clever.dashboard')
      return
    }

    // If a user reaches this point, it means there's a state we haven't captured and need to fix it.
    const err = new Error('clever-id route did not redirect user')
    // this will be handled by application route (logged, and user redirected to error page)
    throw err
  }
}
