import { template as template_54a8b247049f4704a0cfc4b788e9c90f } from "@ember/template-compiler";
import RouteTemplate from 'ember-route-template';
import type { TOC } from '@ember/component/template-only';
export const ManagePage: TOC<unknown> = template_54a8b247049f4704a0cfc4b788e9c90f(`
  <div class="px-8 py-10">
    {{outlet}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ManagePage);
