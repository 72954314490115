import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'
import type UiScopeRoute from 'district-ui-client/reporting/ui-scope/route'
import type StudentEventsService from 'district-ui-client/services/student-events'
import type SessionService from 'district-ui-client/services/session'
import type { NormalizedInterval } from 'date-fns'
import type { SchoolsData, AveragesAndTotalsData } from 'district-ui-client/components/summary-tables/schools'

interface StatsResponse {
  simpleSummary: Nullable<{
    simple_summary: {
      schools: SchoolsData[]
      district: AveragesAndTotalsData
    }
  }>
}

export default class ReportingReSummaryTableRoute extends Route {
  @service studentEvents!: StudentEventsService

  @service session!: SessionService

  async model() {
    const product: LegacyProductRe = 're'
    const { uiScope: scope, period, scopedData } = this.modelFor('reporting.ui-scope') as ModelFor<UiScopeRoute>

    const stats = await this.makeStatsRequest(product, period)
    return {
      product,
      scope,
      period,
      scopedData,
      ...stats,
    }
  }

  makeStatsRequest(product: string, period: NormalizedInterval): Promise<StatsResponse> {
    const districtScope = {
      ids: [this.session.currentDistrict.id],
      scope: 'district',
    }

    return RSVP.hash({
      simpleSummary: this.studentEvents.simpleSummary(product, districtScope, period),
    })
  }
}
