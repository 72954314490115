import Route from '@ember/routing/route'
import { service } from '@ember/service'
import type Store from '@ember-data/store'
import type SessionService from 'district-ui-client/services/session'
import type SubscriptionTypeRoute from 'district-ui-client/subscription-type/route'
import type SchoolStatistic from 'district-ui-client/models/school-statistic'
import type ManageSchoolsRoute from 'district-ui-client/subscription-type/manage/schools/route'

export default class ManageSchoolsIndexRoute extends Route {
  @service store!: Store

  @service session!: SessionService

  async model() {
    const { subscriptionType } = this.modelFor('subscription-type') as ModelFor<SubscriptionTypeRoute>
    const { schools } = this.modelFor('subscription-type.manage.schools') as ModelFor<ManageSchoolsRoute>
    const activeSchools = schools.filter((school) => school.utilisationFor(subscriptionType))
    let schoolStats: SchoolStatistic[] = []
    if (activeSchools.length) {
      const schoolStatsResult = await this.store.query('school-statistic', {
        scope: `districts/${this.session.currentDistrict.id}`,
        filter: { 'school-ids': activeSchools.map((s) => s.id).join(',') },
      })
      schoolStats = schoolStatsResult.slice()
    }
    return { schools: activeSchools.slice(), schoolStats }
  }
}
