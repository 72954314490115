import { template as template_4386cf485783429bb1c489ebfeebd44d } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLUListElement;
    Blocks: {
        default: [];
    };
}
export const ActionMenu: TOC<Signature> = template_4386cf485783429bb1c489ebfeebd44d(`
  <ul
    role="menu"
    class="divide-dusty-black-100 border-dusty-black-100 my-px min-w-[10rem] divide-y rounded-md border bg-white shadow-xl print:hidden"
    ...attributes
  >
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionMenu;
