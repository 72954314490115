import { template as template_f7072be6418f449ca261b8ea3b9d2f04 } from "@ember/template-compiler";
import type { Log } from '@blakeelearning/log';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type CleverStudent from 'district-ui-client/models/clever/clever-student';
import type CleverService from 'district-ui-client/services/clever';
import type FlashQueueService from 'district-ui-client/services/flash-queue';
import { t, type IntlService } from 'ember-intl';
import { Tooltip } from 'district-ui-client/components/tooltip';
import { FormComponentsFormSelect } from 'district-ui-client/components/form-components/form-select';
import { UiButton } from 'district-ui-client/components/ui-button';
import { UiButtonState } from 'district-ui-client/components/ui-button/state';
import fullGradeName from 'district-ui-client/helpers/full-grade-name';
import { not } from 'ember-truth-helpers';
import { fn } from '@ember/helper';
import gradeName from 'district-ui-client/helpers/grade-name';
import { on } from '@ember/modifier';
import type { SelectOption } from 'district-ui-client/components/form-components/form-select/option';
const optionTemplate = '${text}${grade}';
interface BlakeStudentOption extends SelectOption {
    grade?: string;
    text: string;
    value: string;
}
interface Signature {
    Args: {
        disableMatchButtons?: boolean;
        data: CleverStudent;
        matchAction: (cleverRecord: CleverStudent, createOrId: Nullable<string>) => Promise<void>;
        resetAction: (cleverRecord: CleverStudent) => Promise<void>;
        blakeUserOptions: BlakeStudentOption[];
        highlightClass: string;
        onSelect?: () => void;
        isSelected: boolean;
    };
    Element: HTMLTableRowElement;
}
export class MatchStudentsTableRowComponent extends Component<Signature> {
    @service
    clever: CleverService;
    @service
    flashQueue: FlashQueueService;
    @service
    log: Log;
    @service
    intl: IntlService;
    @tracked
    highlight = false;
    get cleverRecord() {
        return this.args.data;
    }
    get isResetButtonDisabled() {
        return this.args.disableMatchButtons || this.isMatchInProgress;
    }
    get isSubmitButtonDisabled() {
        return this.args.disableMatchButtons || !this.selectedOption || this.isMatchInProgress;
    }
    get buttonText() {
        if (this.isCreateOptionSelected) {
            return this.intl.t('clever.createWord');
        } else {
            return this.intl.t('clever.matchWord');
        }
    }
    // The option selected in the match dropdown
    @tracked
    _selectedOption: Nullable<BlakeStudentOption> = null;
    get selectedOption() {
        const { allOptions, _selectedOption } = this;
        /* A previously selected option may no longer be a valid option anymore, if it was matched in a dropdown on a
     * different row. So the find() call is to check that the option selected is within the available options.
     */ return allOptions.find((option)=>option.value === _selectedOption?.value);
    }
    set selectedOption(value) {
        this._selectedOption = value ?? null;
    }
    get isCreateOptionSelected() {
        return this.selectedOption?.value === 'create';
    }
    get selectedBlakeUserId() {
        if (this.isCreateOptionSelected) return null;
        return this.selectedOption?.value ?? null;
    }
    optionClick = (option: BlakeStudentOption)=>{
        this.selectedOption = option;
    };
    @tracked
    isMatchInProgress = false;
    match = async ()=>{
        this.isMatchInProgress = true;
        await this.args.matchAction(this.cleverRecord, this.isCreateOptionSelected ? 'create' : this.selectedBlakeUserId);
        this.isMatchInProgress = false;
    };
    reset = async ()=>{
        this.isMatchInProgress = true;
        await this.args.resetAction(this.cleverRecord);
        this.isMatchInProgress = false;
    };
    /**
   * set the background colour of the row when we open the dropdown to keep the attention
   * on the selected row
   * @returns void
   */ dropdownOpen = ()=>{
        this.highlight = true;
    };
    /**
   * remove the background colour of the row when we open the dropdown
   * @returns void
   */ dropdownClose = ()=>{
        this.highlight = false;
    };
    get allOptions() {
        return [
            ...this.actionOptions,
            ...this.args.blakeUserOptions
        ];
    }
    get actionOptions() {
        const createNewStudentText = this.intl.t('clever.createNewStudentText');
        const createStudentOption = {
            text: createNewStudentText,
            value: 'create'
        };
        return [
            createStudentOption
        ];
    }
    static{
        template_f7072be6418f449ca261b8ea3b9d2f04(`
    <tr data-test-students-table-row class={{if this.highlight @highlightClass}} ...attributes>
      {{#if @onSelect}}
        <td class="td-checkbox text-center">
          <input
            data-test-match-record-checkbox
            type="checkbox"
            checked={{@isSelected}}
            aria-label={{if @isSelected (t "clever.deselectStudent") (t "clever.selectStudent")}}
            {{on "click" (fn @onSelect (not @isSelected))}}
          />
        </td>
      {{/if}}
      <td class="td-sisid" data-test-student-cell-sisid={{@data.sisId}}>
        <div class="truncate">
          {{@data.sisId}}
          <Tooltip @text={{@data.sisId}} />
        </div>
      </td>
      <td>
        <div>
          {{@data.firstName}}
        </div>
      </td>
      <td>
        <div>
          {{@data.lastName}}
        </div>
      </td>
      <td>
        <div class="text-center">
          {{gradeName @data.gradePosition}}
        </div>
      </td>
      <td class="td-matching">
        <div>
          {{#if @data.matched}}
            {{#if @data.blakeStudent}}
              <span>{{@data.blakeStudent.fullName}}: {{fullGradeName @data.blakeStudent.gradePosition}}</span>
            {{else}}
              <span></span>
            {{/if}}
          {{else}}
            <FormComponentsFormSelect
              @defaultText={{t "clever.selectStudent"}}
              @search={{true}}
              @options={{@blakeUserOptions}}
              @staticBeforeOptions={{this.actionOptions}}
              @value={{this.selectedOption.value}}
              @searchPlaceholder={{t "clever.searchStudentsPlaceholder"}}
              @optionClick={{this.optionClick}}
              @openAction={{this.dropdownOpen}}
              @closeAction={{this.dropdownClose}}
              @optionTemplate={{optionTemplate}}
              @selectedTemplate={{optionTemplate}}
            />
          {{/if}}
        </div>
      </td>
      <td>
        {{#if @data.matched}}
          <UiButton
            data-test-reset-match-student
            class="{{if this.isResetButtonDisabled 'muted' 'regular'}}"
            disabled={{this.isResetButtonDisabled}}
            {{on "click" this.reset}}
          >
            <UiButtonState @isLoading={{this.isMatchInProgress}}>
              {{t "clever.resetMatchText"}}
            </UiButtonState>
          </UiButton>

        {{else}}
          <UiButton
            data-test-submit-match-student
            class="{{if this.isSubmitButtonDisabled 'muted' 'regular'}}"
            disabled={{this.isSubmitButtonDisabled}}
            {{on "click" this.match}}
          >
            <UiButtonState @isLoading={{this.isMatchInProgress}}>
              {{this.buttonText}}
            </UiButtonState>
          </UiButton>
        {{/if}}
      </td>
    </tr>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default MatchStudentsTableRowComponent;
