import { template as template_13f1b79c1f044aa0bc5ec82fb03ee06c } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import BlakeColors from '@blakeelearning/blake-colours/colours';
import type GradeSetsService from 'district-ui-client/services/grade-sets';
import SeriesBar from 'district-ui-client/components/primitives/series-bar/component';
import { t } from 'ember-intl';
import type { SeriesBarOptions } from 'highcharts';
/**
 * Turns a student-events course_progress_by_grade response into a giraffe chart
 * using the dashboard-title and series-bar primitives.

 */ export interface CourseProgressData {
    course_progress_by_grade?: {
        grades?: {
            grade_position: number;
            lesson_count: number;
            student_count: number;
        }[];
        student_count: number;
    };
}
interface Signature {
    Args: {
        data: CourseProgressData;
        product: 're' | 'rex' | 'maths';
        precinct: 'reading' | 'spelling' | 'my_lessons' | 'lessons';
    };
    Element: HTMLDivElement;
}
export class ChartsDashboardCourseProgress extends Component<Signature> {
    @service
    gradeSets: GradeSetsService;
    /**
   * The series labels for the chart, formatted for Highcharts.
   * A list of formatted grade names.
   */ get categories() {
        return (this.args.data?.course_progress_by_grade?.grades?.map((grade)=>this.gradeSets.findByPosition(grade.grade_position)?.name ?? '-') ?? []);
    }
    get color() {
        const { product, precinct } = this.args;
        switch(`${product}.${precinct}`){
            case 'maths.lessons':
                return BlakeColors.forestGreen300;
            case 're.spelling':
            case 'rex.spelling':
                return BlakeColors.grapeyGreen300;
            case 're.reading':
            case 'rex.my_lessons':
                return BlakeColors.oceanyBlue300;
            default:
                return BlakeColors.oceanyBlue300;
        }
    }
    /**
   * Bar data formatted for Highcharts
   */ get bars(): SeriesBarOptions[] {
        const color = this.color;
        const byGrade = this.args.data.course_progress_by_grade?.grades ?? [];
        return [
            {
                data: byGrade.map((grade)=>grade.student_count === 0 ? 0 : Math.round(grade.lesson_count / grade.student_count)),
                color,
                type: 'bar'
            }
        ];
    }
    /**
   * Flag if data has only one grade
   */ get singleGrade() {
        return this.args.data?.course_progress_by_grade?.grades?.length === 1;
    }
    /**
   * Total number of students across all grades
   * Calculates number of contributing students
   */ get studentCount() {
        return formatNumber(this.args.data?.course_progress_by_grade?.student_count ?? 0, 2);
    }
    static{
        template_13f1b79c1f044aa0bc5ec82fb03ee06c(`
    <div data-test-dashboard-course-progress class="flex h-full flex-col" ...attributes>
      <div data-test-dashboard-subtitle class="flex justify-between gap-4 text-xs">
        <span>
          {{#if this.singleGrade}}
            {{t "reporting.dashboardSubtitles.singleGrade.courseProgress"}}
          {{else}}
            {{t "reporting.dashboardSubtitles.multiGrade"}}
          {{/if}}
        </span>
        <span>
          {{t "reporting.dashboardSubtitles.studentCount" count=this.studentCount}}
        </span>
      </div>

      {{#if this.singleGrade}}
        <SeriesBar
          @data={{this.bars}}
          @categories={{this.categories}}
          @xAxisLabel={{t "grade.label"}}
          @yAxisLabel={{t "myLessons"}}
          @groupPadding={{0}}
          @barPadding={{0}}
          @chartMargin={{Array 55 0 104 0}}
          @yAxisOffset={{25}}
          @yAxisLineWidth={{1}}
          @yAxisGridLineWidth={{0}}
        />
      {{else}}
        <SeriesBar
          @data={{this.bars}}
          @categories={{this.categories}}
          @xAxisLabel={{t "grade.label"}}
          @yAxisLabel={{t "myLessons"}}
        />
      {{/if}}

    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChartsDashboardCourseProgress;
