import { template as template_36d50616d25c49dfb0a706d6ffab1ddf } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import CleverUiModal from 'district-ui-client/components/clever-ui/modal';
import CleverUiConfirmDialog from 'district-ui-client/components/clever-ui/confirm-dialog/component';
import { t } from 'ember-intl';
import type RouterService from '@ember/routing/router-service';
import type CleverTeacher from 'district-ui-client/models/clever/clever-teacher';
/**
 * Account exists in different district, user is prompted to contact support.
 */ interface Signature {
    Args: {
        cleverTeacher: CleverTeacher;
        isShowingModal?: boolean;
        positiveAction: () => void;
        negativeAction: () => void;
    };
}
export class ExistsInDifferentDistrictTeacherMatcherModal extends Component<Signature> {
    @service
    router: RouterService;
    get supportUrl() {
        return this.router.urlFor('subscription-type.support');
    }
    static{
        template_36d50616d25c49dfb0a706d6ffab1ddf(`
    <CleverUiModal
      @isShowingModal={{@isShowingModal}}
      @onClose={{@negativeAction}}
      @title={{t "clever.teacherMatcher.errors.existsInDifferentDistrict.summary"}}
      @type="warning"
    >
      <CleverUiConfirmDialog
        @positiveAction={{@positiveAction}}
        @positiveButtonText={{t "close"}}
        @positiveButtonAppearance="orange"
      >
        <p class="text-base">
          {{t
            "clever.teacherMatcher.errors.existsInDifferentDistrict.explanation"
            htmlSafe=true
            firstName=@cleverTeacher.firstName
            lastName=@cleverTeacher.lastName
          }}
        </p>
        <p class="mb-0 text-base">
          {{t
            "clever.teacherMatcher.errors.existsInDifferentDistrict.solution"
            htmlSafe=true
            supportUrl=this.supportUrl
          }}
        </p>
      </CleverUiConfirmDialog>
    </CleverUiModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ExistsInDifferentDistrictTeacherMatcherModal;
