import { template as template_85c231581ec648f992d8e5847fd50702 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const Lozenge: TOC<Signature> = template_85c231581ec648f992d8e5847fd50702(`
  <div
    class="text-pink-350 inline-flex h-6 items-center rounded bg-pink-100 px-1 text-xs font-medium uppercase"
    ...attributes
  >
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
