import { template as template_ca2a2d0403a1408d96e23ee7eb0cf180 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { didCancel, type TaskInstance } from 'ember-concurrency';
import type TeacherMatchErrorService from 'district-ui-client/services/clever/teacher-match-error';
import { ErrorCodes, type ErrorCode } from 'district-ui-client/services/clever/teacher-match-error';
import type FlashQueueService from 'district-ui-client/services/flash-queue';
import type { IntlService } from 'ember-intl';
import type Store from '@ember-data/store';
import type CleverTeacher from 'district-ui-client/models/clever/clever-teacher';
import { hash } from '@ember/helper';
import { TeacherMatchErrorSummary } from 'district-ui-client/components/clever-ui/teacher-match-error/summary';
import { TeacherMatchErrorMoreInfo } from 'district-ui-client/components/clever-ui/teacher-match-error/more-info';
import { TeacherMatchErrorModals } from 'district-ui-client/components/clever-ui/teacher-match-error/modals';
const errorCodeIntlKeyPrefixes = {
    [ErrorCodes.EXISTS_IN_DIFFERENT_DISTRICT]: 'clever.teacherMatcher.errors.existsInDifferentDistrict',
    [ErrorCodes.EXISTS_AS_PARENT]: 'clever.teacherMatcher.errors.existsAsParent',
    [ErrorCodes.EXISTS_AS_PARENT_CONTACT]: 'clever.teacherMatcher.errors.existsAsParentContact',
    [ErrorCodes.ID_MISMATCH_ERROR]: 'clever.teacherMatcher.errors.idMismatchError',
    [ErrorCodes.STALE_ACCOUNT_ERROR]: 'clever.teacherMatcher.errors.staleAccountError',
    [ErrorCodes.INVALID_EMAIL_CHAR]: 'clever.teacherMatcher.errors.invalidEmailChar'
};
interface Signature {
    Args: {
        teacher: CleverTeacher;
        refreshAction?: () => void;
    };
    Blocks: {
        default: [{
                summary: any;
                moreInfo: any;
            }];
    };
}
export class TeacherMatchErrorComponent extends Component<Signature> {
    @service
    flashQueue: FlashQueueService;
    @service
    intl: IntlService;
    @service
    store: Store;
    @service('clever/teacher-match-error')
    teacherMatchError: TeacherMatchErrorService;
    @tracked
    isModalVisible = false;
    @tracked
    _remedyTaskInstance: Nullable<TaskInstance<{
        ok: false;
        error: unknown;
    } | {
        ok: true;
    }>> = null;
    get matchErrorCode() {
        return this.args.teacher.matchErrorCode;
    }
    get summaryText() {
        const defaultPrefix = 'clever.teacherMatcher.errors.unknown';
        const prefix = this.matchErrorCode ? errorCodeIntlKeyPrefixes[this.matchErrorCode] : defaultPrefix // use default if no key defined
        ;
        const defaultSummaryKey = `${defaultPrefix}.summary`;
        const summaryKey = `${prefix}.summary`;
        // use default key if no translation defined for summary key
        return this.intl.exists(summaryKey) ? this.intl.t(summaryKey) : this.intl.t(defaultSummaryKey);
    }
    get _isPerformingRemedy() {
        return this._remedyTaskInstance?.isRunning;
    }
    showModal = async ()=>{
        // If there's some extra data the modal's going to need, fetch it here.
        // In some scenarios, the other teacher may belong to a different school, so it may not be in the store
        const { matchErrorCode } = this;
        const { matchError } = this.args.teacher;
        if (!matchErrorCode) return;
        const isStaleOrMismatch = matchErrorCode === ErrorCodes.STALE_ACCOUNT_ERROR || matchErrorCode === ErrorCodes.ID_MISMATCH_ERROR;
        const otherTeacherId = matchError?.['other-teacher-id'];
        if (isStaleOrMismatch && otherTeacherId) await this.store.findRecord('clever/teacher', otherTeacherId);
        this.isModalVisible = true;
    };
    @action
    hideModal() {
        this.isModalVisible = false;
    }
    @action
    cancel() {
        this.hideModal();
    }
    submit = ()=>{
        const { args: { teacher }, matchErrorCode } = this;
        if (!matchErrorCode) return;
        if (this.teacherMatchError.hasRemedyForCode(matchErrorCode)) {
            this._remedyTaskInstance = this.teacherMatchError.remedy.perform(teacher, matchErrorCode, {
                onComplete: this.onRemedyComplete,
                onError: this.onRemedyError
            });
        }
        this.hideModal();
    };
    onRemedyComplete = async (teacher: CleverTeacher, matchErrorCode: ErrorCode)=>{
        try {
            const { refreshAction } = this.args;
            if (refreshAction) await this.teacherMatchError.postRemedyRefresh.perform(teacher, matchErrorCode, refreshAction);
        } catch (e) {
            if (!didCancel(e)) throw e;
        }
        this.flashQueue.addSuccess({
            subtitle: this.intl.t('clever.teacherMatcher.remedySuccess')
        });
    };
    onRemedyError = (_teacher: CleverTeacher, _matchErrorCode: ErrorCode, _remedyError: unknown)=>{
        this.flashQueue.addFail({
            subtitle: this.intl.t('clever.teacherMatcher.remedyFail')
        });
    };
    static{
        template_ca2a2d0403a1408d96e23ee7eb0cf180(`
    {{yield
      (hash
        summary=(component TeacherMatchErrorSummary matchErrorCode=this.matchErrorCode summaryText=this.summaryText)
        moreInfo=(component
          TeacherMatchErrorMoreInfo
          isPerformingRemedy=this._isPerformingRemedy
          matchErrorCode=this.matchErrorCode
          showModal=this.showModal
        )
      )
    }}
    <TeacherMatchErrorModals
      @errorCode={{this.matchErrorCode}}
      @isShowingModal={{this.isModalVisible}}
      @cleverTeacher={{@teacher}}
      @positiveAction={{this.submit}}
      @negativeAction={{this.cancel}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default TeacherMatchErrorComponent;
