import { template as template_da0b1415fdd24464840e969fded6d5fb } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
export const DividerHorizontal: TOC<{
    Element: HTMLHRElement;
}> = template_da0b1415fdd24464840e969fded6d5fb(`
  <hr class="h-[2px] bg-neutral-50" ...attributes />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
