import { template as template_0d01d34891d54c88827740bbadf84725 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { t } from 'ember-intl';
import { on } from '@ember/modifier';
import { BaseModal } from 'district-ui-client/components/base-modal';
import { ThemedButton } from 'district-ui-client/components/themed-button';
interface Signature {
    Element: HTMLDivElement;
    Args: {
        show?: boolean;
        close: () => void;
        containerClass?: string;
    };
    Blocks: {
        default: [];
    };
}
export const ThemedModal: TOC<Signature> = template_0d01d34891d54c88827740bbadf84725(`
  {{#if @show}}
    <BaseModal @close={{@close}} @containerClass={{@containerClass}}>
      <div ...attributes data-test-modal>
        {{yield}}
      </div>
    </BaseModal>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface ModalHeaderSignature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const ModalHeader: TOC<ModalHeaderSignature> = template_0d01d34891d54c88827740bbadf84725(`
  <div class="flex items-center bg-white px-6 pb-4 pt-6" ...attributes data-test-modal-header>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface ModalHeadingSignature {
    Element: HTMLHeadingElement;
    Blocks: {
        default: [];
    };
}
export const ModalHeading: TOC<ModalHeadingSignature> = template_0d01d34891d54c88827740bbadf84725(`
  <h3 class="m-0 flex-grow text-xl font-medium leading-6 text-neutral-400" ...attributes data-test-modal-heading>
    {{yield}}
  </h3>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface ModalBodySignature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const ModalBody: TOC<ModalBodySignature> = template_0d01d34891d54c88827740bbadf84725(`
  <div class="px-6 py-2" ...attributes data-test-modal-body>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface ModalFooterSignature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const ModalFooter: TOC<ModalFooterSignature> = template_0d01d34891d54c88827740bbadf84725(`
  <div class="flex justify-end space-x-2 px-6 pb-6 pt-4" ...attributes data-test-modal-footer>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
interface CloseButtonSignature {
    Args: {
        close: () => void;
    };
}
export const CloseButton: TOC<CloseButtonSignature> = template_0d01d34891d54c88827740bbadf84725(`
  <ThemedButton
    @style="icon-only"
    type="button"
    class="text-base leading-4"
    aria-label={{t "close"}}
    {{on "click" @close}}
    data-test-modal-close
  >
    <FaIcon class="h-4 w-4" @icon="xmark" />
  </ThemedButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
