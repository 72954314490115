import { template as template_2973b98455a74f4488befd868e5de7a1 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type ReStorylandsRoute from 'district-ui-client/reporting/ui-scope/re/storylands/route';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { ChartsStorylandsLessonsTotals } from 'district-ui-client/components/charts/storylands-lessons/totals/component';
import fullGradeName from 'district-ui-client/helpers/full-grade-name';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import { not } from 'ember-truth-helpers';
interface Signature {
    Args: {
        model: ModelFor<ReStorylandsRoute>;
    };
}
export const ReportingReStorylandsRouteTemplate: TOC<Signature> = template_2973b98455a74f4488befd868e5de7a1(`
  <DetailedPageWrapper @empty={{not @model.storylandsTotals}}>
    {{#each @model.storylandsTotals.storylands_totals as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsStorylandsLessonsTotals
          class="h-full"
          @data={{gradeData}}
          @grade={{fullGradeName gradeData.grade_position}}
          @tooltip={{t "reporting.tooltips.storylandsTotals"}}
          @title={{t "reporting.chartTitles.storylandsTotals"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ReportingReStorylandsRouteTemplate);
