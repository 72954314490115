import { template as template_c5e36b2ff4d94c7c8d6554c74fe9f0d7 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Teacher from 'district-ui-client/models/teacher';
import type School from 'district-ui-client/models/school';
import { ModalDefault } from 'district-ui-client/components/modal-default';
import FidgetSpinnerWaveComponent from '@blakeelearning/fidget/components/fidget/spinner/wave';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import BaseSelect from 'district-ui-client/components/base/select';
import { UiButton } from 'district-ui-client/components/ui-button';
import { on } from '@ember/modifier';
import { eventValue } from 'district-ui-client/helpers/event-value';
import { or } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import themeKey from 'district-ui-client/helpers/theme-key';
interface Signature {
    subscriptionType: SubscriptionType;
    isSubmitting?: boolean;
    schools: School[];
    onClose: () => void;
    onSchoolAndTeacherSelected: ({ school, teacher }: {
        school: School;
        teacher?: Teacher;
    }) => void;
}
export class ChangeSchoolModal extends Component<Signature> {
    @tracked
    newSchool?: School;
    @tracked
    newTeacher?: Teacher;
    get teachersForSubscriptionType() {
        return (this.newSchool?.teachersForSubscriptionType(this.args.subscriptionType).sort((a, b)=>a.fullNameReversed.localeCompare(b.fullNameReversed)) ?? []);
    }
    // can submit if no teacher given, will just move student to school
    get submitDisabled() {
        return !this.newSchool || this.args.isSubmitting;
    }
    setSchool = (id: string)=>{
        this.newSchool = this.args.schools.find((school)=>school.id === id);
    };
    setTeacher = (id: string)=>{
        this.newTeacher = this.teachersForSubscriptionType.find((teacher)=>teacher.id === id);
    };
    onSubmit = ()=>{
        if (this.newSchool) this.args.onSchoolAndTeacherSelected({
            school: this.newSchool,
            teacher: this.newTeacher
        });
    };
    static{
        template_c5e36b2ff4d94c7c8d6554c74fe9f0d7(`
    <ModalDefault data-test-modal-content="change-school" @onClose={{@onClose}} as |modal|>
      <modal.header>
        {{t "manage.students.groupActions.changeSchool.title"}}
        {{#if @isSubmitting}}
          <div class="ml-1 inline-block align-middle">
            <FidgetSpinnerWaveComponent @small={{true}} />
          </div>
        {{/if}}
      </modal.header>
      <modal.body class="space-y-4">
        <div>
          <BaseSelect
            data-test-select-school
            class="min-w-1/2"
            value={{or this.newSchool.id ""}}
            disabled={{@isSubmitting}}
            @themeKey={{themeKey @subscriptionType}}
            {{on "input" (eventValue this.setSchool)}}
          >
            <option value="">{{t "manage.students.groupActions.changeSchool.schoolPlaceholder"}}</option>
            {{#each @schools as |school|}}
              <option value={{school.id}}>{{school.name}}</option>
            {{/each}}
          </BaseSelect>
        </div>

        <div>
          <BaseSelect
            data-test-select-teacher
            class="min-w-1/2"
            value={{or this.newTeacher.id ""}}
            disabled={{@isSubmitting}}
            @themeKey={{themeKey @subscriptionType}}
            {{on "input" (eventValue this.setTeacher)}}
          >
            <option value="">{{t "manage.students.groupActions.changeSchool.teacherPlaceholder"}}</option>
            {{#each this.teachersForSubscriptionType as |teacher|}}
              <option value={{teacher.id}}>{{teacher.fullNameReversed}}</option>
            {{/each}}
          </BaseSelect>
        </div>

        <UiButton @buttonType="submit" class="regular" {{on "click" this.onSubmit}} disabled={{this.submitDisabled}}>
          {{t "manage.students.groupActions.changeSchool.submitButton"}}
        </UiButton>
      </modal.body>
    </ModalDefault>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChangeSchoolModal;
