import { template as template_2012f4481f6c4df2a87eec1549bfdbdc } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import type CleverTeacher from 'district-ui-client/models/clever/clever-teacher';
import { ConfirmWithCheckbox } from 'district-ui-client/components/clever-ui/modals/confirm-with-checkbox';
/**
 * Given Clever ID or SIS ID already in use, user can re-assign the in-use
 * Clever ID or SIS ID and delete the teacher account for other teacher.
 */ interface Signature {
    Args: {
        isShowingModal?: boolean;
        positiveAction: () => void;
        negativeAction: () => void;
        cleverTeacher: CleverTeacher;
    };
}
export const ExistsAsParentContactTeacherMatcherModal: TOC<Signature> = template_2012f4481f6c4df2a87eec1549bfdbdc(`
  <ConfirmWithCheckbox
    @isShowingModal={{@isShowingModal}}
    @title={{t "clever.teacherMatcher.errors.existsAsParentContact.summary"}}
    @positiveAction={{@positiveAction}}
    @positiveButtonText={{t "clever.teacherMatcher.errors.existsAsParentContact.positiveButtonText"}}
    @negativeAction={{@negativeAction}}
    @confirmLabel={{t "clever.teacherMatcher.errors.existsAsParentContact.confirmLabel"}}
  >
    <p class="text-base">
      {{t "clever.teacherMatcher.errors.existsAsParentContact.explanation" htmlSafe=true email=@cleverTeacher.email}}
    </p>
    <p class="mb-0 text-base">
      {{t "clever.teacherMatcher.errors.existsAsParentContact.solution" htmlSafe=true}}
    </p>
  </ConfirmWithCheckbox>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ExistsAsParentContactTeacherMatcherModal;
