import { template as template_d048ec9056b348dc91f560d0da1904e1 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { PopoverTooltipComponent } from '@blakeelearning/popovers/components/popover/tooltip';
import type { SafeString } from '@ember/template';
interface TooltipSignature {
    Element: HTMLElement;
    Args: {
        text?: string | SafeString;
    };
    Blocks: {
        default: [];
    };
}
/*
 * w-max is necessary, else you can get very rare occurences of ResizeObserver errors, that may not replicate locally
 *
 * https://github.com/floating-ui/floating-ui/issues/1740#issuecomment-1563544849
 * https://github.com/floating-ui/floating-ui/issues/2338
 *
 * It may not be necessary if our implementation of tooltips swap from "layout" (top/left) positioning to "transform"
 * positioning.
 */ export const classNames = 'w-max max-w-60 rounded bg-neutral-400 px-[10px] py-2 text-center text-sm font-medium';
/**
 * Reexport the addon tooltip but with DUI's style
 */ export const Tooltip: TOC<TooltipSignature> = template_d048ec9056b348dc91f560d0da1904e1(`
  {{#if @text}}
    <PopoverTooltipComponent data-test-tooltip class={{classNames}} @text={{@text}} ...attributes />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default Tooltip;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        Tooltip: typeof Tooltip;
    }
}
interface TooltipInfoSignature {
    Element: HTMLSpanElement;
    Args: {
        text?: string | SafeString;
    };
    Blocks: {
        default: [];
    };
}
/**
 * Tooltip, with an info icon.
 *
 * The icon is always 1.25em (em, 1.25* the current font-size), so lg size class is used by default. Should you need a
 * different size, consider applying a font size class to this component.
 */ export const TooltipInfo: TOC<TooltipInfoSignature> = template_d048ec9056b348dc91f560d0da1904e1(`
  {{#if @text}}
    <span class="print:hidden" ...attributes>
      <FaIcon @size="lg" @icon="circle-info" class="cursor-pointer" />
      <Tooltip @text={{@text}} />
    </span>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
