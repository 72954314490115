import { service } from '@ember/service'
import Route from '@ember/routing/route'
import RSVP from 'rsvp'
import type StudentEventsService from 'district-ui-client/services/student-events'
import type SessionService from 'district-ui-client/services/session'
import type ReportingUiScopeRoute from 'district-ui-client/reporting/ui-scope/route'
import type { StatsScope } from 'district-ui-client/utils/student-events/stats-scope'
import type { NormalizedInterval } from 'date-fns'
import type { SchoolsData, AveragesAndTotalsData } from 'district-ui-client/components/summary-tables/schools'

interface StatsResponse {
  simpleSummary: Nullable<{ simple_summary: { schools: SchoolsData[]; district: AveragesAndTotalsData } }>
}

export default class ReportingMathsSummaryTableRoute extends Route {
  @service studentEvents!: StudentEventsService

  @service session!: SessionService

  async model() {
    const product: LegacyProductMs = 'maths'
    const {
      uiScope: scope,
      statsScope,
      period,
      scopedData,
    } = this.modelFor('reporting.ui-scope') as ModelFor<ReportingUiScopeRoute>

    const stats = await this.makeStatsRequest(product, statsScope, period)
    return {
      product,
      scope,
      period,
      scopedData,
      ...stats,
    }
  }

  makeStatsRequest(product: string, scope: StatsScope, period: NormalizedInterval): Promise<StatsResponse> {
    const districtScope = {
      ids: [this.session.currentDistrict.id],
      scope: 'district',
    }

    return RSVP.hash({
      simpleSummary: this.studentEvents.simpleSummary(product, districtScope, period),
    })
  }
}
