import { template as template_f9a8da6985234e62a2ba9e755b417ddb } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { t } from 'ember-intl';
import { PaneHeader } from 'district-ui-client/components/pane/header';
import { ThemedLinkTo } from 'district-ui-client/components/themed-button';
export const ManagePane: TOC<unknown> = template_f9a8da6985234e62a2ba9e755b417ddb(`
  <PaneHeader class="mb-8">{{t "sideNav.manage"}}</PaneHeader>
  <div class="flex flex-col gap-5">
    <ThemedLinkTo @style="text" @route="subscription-type.manage.schools">{{t "schools"}}</ThemedLinkTo>
    <ThemedLinkTo @style="text" @route="subscription-type.manage.teachers">{{t "teachers"}}</ThemedLinkTo>
    <ThemedLinkTo @style="text" @route="subscription-type.manage.students">{{t "students"}}</ThemedLinkTo>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
