import type Store from '@ember-data/store'
import Route from '@ember/routing/route'
import { service } from '@ember/service'
import type SubscriptionTypeRoute from 'district-ui-client/subscription-type/route'
import type SessionService from 'district-ui-client/services/session'

export default class ManageTeachersIndexRoute extends Route {
  @service store!: Store

  @service session!: SessionService

  queryParams = {
    schoolId: {
      replace: true,
      refreshModel: false,
    },
  }

  async model() {
    const { subscriptionType } = this.modelFor('subscription-type') as ModelFor<SubscriptionTypeRoute>

    const schoolsForSubType = (
      await this.store.query('school', {
        scope: `districts/${this.session.currentDistrict.id}`,
        include: 'school-subscriptions',
      })
    ).filter((school) => school.hasActiveSchoolSubscriptionFor(subscriptionType))

    return { schoolsForSubType, subscriptionType }
  }
}
