import Model, { attr, belongsTo } from '@ember-data/model'
import type CleverDistrictMatch from 'district-ui-client/models/clever/clever-district-match'
import type CleverTeacher from 'district-ui-client/models/clever/clever-teacher'
import type GravityTeacher from 'district-ui-client/models/clever/teacher'

/**
 * Model class for clever sisId and district id.
 *
 * @class CleverTeacherMatchModel
 * @property {string} cleverId - clever id as a string
 * @property {string} sisId - unique id which relates to a clever record fo this type
 * @property {boolean} missingEmail - boolean to indicate if the teacher has a missing email address
 * @property {BlakeTeacherModel} teacher - Blake teacher model
 * @property {CleverTeacherModel} cleverTeacher - clever teacher model
 * @property {CleverDistrictMatchModel} cleverDistrictMatch - Clever district match
 */
export default class CleverTeacherMatch extends Model {
  @attr('string') cleverId!: string

  @attr('string') sisId!: string

  @attr('boolean', { defaultValue: false }) missingEmail!: boolean

  @belongsTo('clever/teacher', { async: false, inverse: 'cleverTeacherMatch' })
  teacher?: GravityTeacher

  @belongsTo('clever/clever-teacher', { async: false, inverse: 'cleverTeacherMatch' })
  cleverTeacher?: CleverTeacher

  // Provided when creating a school match record
  @belongsTo('clever/clever-district-match', { async: false, inverse: null })
  cleverDistrictMatch?: CleverDistrictMatch
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'clever/clever-teacher-match': CleverTeacherMatch
  }
}
