import Route from '@ember/routing/route'
import type SubscriptionTypeRoute from 'district-ui-client/subscription-type/route'

export default class SupportRoute extends Route {
  model() {
    const { subscriptionType } = this.modelFor('subscription-type') as ModelFor<SubscriptionTypeRoute>
    return {
      subscriptionType,
    }
  }
}
