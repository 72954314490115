import { template as template_2f2d0fd3d4d0428ba2aea236a3ed8863 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { reportingLegend } from 'district-ui-client/utils/giraffe/highcharts-config';
import { basicAverage } from 'district-ui-client/utils/giraffe/average-calculator';
import convertDateFormat from 'district-ui-client/utils/giraffe/convert-date-format';
import { formatNumber } from 'district-ui-client/utils/giraffe/formatter';
import colors from '@blakeelearning/blake-colours/colours';
import type { IntlService } from 'ember-intl';
import type { SeriesColumnOptions } from 'highcharts';
import { LeftSplitChart } from 'district-ui-client/components/left-split-chart';
import { SummaryBox, SummaryBoxColorScheme } from 'district-ui-client/components/summary-box';
import { t } from 'ember-intl';
import { SeriesColumn } from 'district-ui-client/components/primitives/series-column/component';
import { TooltipInfo } from 'district-ui-client/components/tooltip';
/**
 * Puts together all the pieces for the books read by genre totals chart.
 *
 * Data format:
 *
 * ```
 * {
 *   grade_position: 1,
 *   summary: [
 *     { category: 'total_count', count: 120 },
 *     { category: 'student_count', count: 20 },
 *   ],
 *   months: [
 *     {
 *       fiction: 5,
 *       nonfiction: 5,
 *       month: '2016-07'
 *     },
 *     {
 *       fiction: 5,
 *       nonfiction: 20,
 *       month: '2016-08'
 *     }
 *   ],
 * },
 * ```
 */ export interface BooksReadByGenreData {
    grade_position: number;
    summary: {
        category: string;
        count: number;
    }[];
    months: {
        fiction: number;
        nonfiction: number;
        month: string;
    }[];
}
interface Signature {
    Args: {
        data?: BooksReadByGenreData;
        grade?: string;
        tooltip?: string;
    };
    Element: HTMLDivElement;
}
export class ChartsBooksReadByGenreTotals extends Component<Signature> {
    @service
    intl: IntlService;
    /**
   * Computed list of summary statistics, extracted from the input data.
   * Display total books read and student counts, and average books per student.
   */ get summaryStats() {
        const summaryData = this.args.data?.summary ?? [];
        const total = summaryData.find((d)=>d.category === 'total_count')?.count ?? 0;
        const studentCount = summaryData.find((d)=>d.category === 'student_count')?.count ?? 0;
        const average = basicAverage(total, studentCount);
        return [
            {
                label: this.intl.t('reporting.statBoxes.courseProgress.booksRead'),
                value: formatNumber(total, 1)
            },
            {
                label: this.intl.t('reporting.statBoxes.courseProgress.studentCount'),
                value: formatNumber(studentCount, 1)
            },
            {
                label: this.intl.t('reporting.statBoxes.courseProgress.perStudentCount'),
                value: formatNumber(average, 1)
            }
        ];
    }
    /**
   * Computed list of column data, extracted from the input data.
   * Formats month data for Highcharts.
   */ get columns(): SeriesColumnOptions[] {
        const monthData = this.args.data?.months ?? [];
        const classificationColors = {
            fiction: colors.purplyPink400,
            nonfiction: colors.purplyPink300
        };
        const genres = [
            'fiction',
            'nonfiction'
        ] as const;
        return genres.map((genre)=>{
            const name = this.intl.t(`reporting.charts.booksRead.genres.${genre}`);
            const data = monthData.map((m)=>m[genre]);
            const color = classificationColors[genre];
            return {
                name,
                data,
                color,
                type: 'column'
            };
        });
    }
    /**
   * Computed list of category data, extracted from the input data.
   * Formats month data for Highcharts for use as column labels.
   * Converts month dates into short names.
   */ get categories() {
        return this.args.data?.months?.map((m)=>convertDateFormat(m.month)) ?? [];
    }
    get legend() {
        return reportingLegend('left', 'top', 50, -15);
    }
    static{
        template_2f2d0fd3d4d0428ba2aea236a3ed8863(`
    <div data-test-books-read-by-genre-totals class="relative" ...attributes>
      <LeftSplitChart>
        <:left>
          <SummaryBox
            class="h-full"
            @grade={{@grade}}
            @stats={{this.summaryStats}}
            @colorScheme={{SummaryBoxColorScheme.Pink}}
          />
        </:left>
        <:right>
          <TooltipInfo class="z-tooltip absolute right-3 top-3 text-xs print:hidden" @text={{@tooltip}} />
          <SeriesColumn
            @title={{t "reporting.chartTitles.booksReadByGenreTotals"}}
            @data={{this.columns}}
            @categories={{this.categories}}
            @stackingStyle="normal"
            @legend={{this.legend}}
            @yAxisReversedStacks={{false}}
            @chartSpacing={{Array 10 10 10 10}}
          />
        </:right>
      </LeftSplitChart>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ChartsBooksReadByGenreTotals;
