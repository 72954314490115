import { classNames } from '@ember-decorators/component'
import { service } from '@ember/service'
import { notEmpty, filterBy, filter, equal, and, readOnly } from '@ember/object/computed'
import { isEmpty } from '@ember/utils'
import Component from '@ember/component'
import { set, action, computed } from '@ember/object'

/**
 * Manages the creating, and editing of student matches
 * @class MatchTeachersTableComponent
 * @property {String} activeTab - current active tab
 * @property {Array<TeacherModel>} blakeTeachers - existing teachers to be matched against
 * @property {Array<CleverTeacherModel>} teacherMatchRows - clever teachers ready to be matched
 * @property {TaskInstance} bulkCreateTeacher - task for performing the bulk creation of teachers
 */
@classNames('__clever-ui__match-teachers-table__3052f')
export default class MatchTeachersTable extends Component {
  filterPlaceholder = 'search'

  showCreateUsersModal = false

  @service intl

  @service session

  @computed('activeTab')
  get matchColumnText() {
    const { activeTab } = this
    const { intl } = this
    return intl.t(`clever.${activeTab}ColumnText`)
  }

  subscriptionType = null

  get productName() {
    return this.intl.t(`subscriptions.${this.subscriptionType}`)
  }

  @computed('blakeTeachers.@each.{cleverTeacherMatch,sisId}')
  get blakeTeachersOptions() {
    const blakeTeachers = this.blakeTeachers || []
    const unmatchedBlakeTeachers = blakeTeachers.filter((teacher) => {
      return !teacher.belongsTo('cleverTeacherMatch').value() || isEmpty(teacher.get('sisId'))
    })

    return unmatchedBlakeTeachers.map((teacher) => {
      return {
        text: teacher.fullName,
        value: teacher.id,
        uid: teacher.id,
        email: teacher.email,
      }
    })
  }

  @computed('teacherMatchRows.@each.matched')
  get records() {
    /* this is here to force the records property to become dirty
       so that the item count in the tabs updates properly
       TODO: should investigate and refactor this properly
    */
    return this.teacherMatchRows.map((item) => item)
  }

  get isTaskLoading() {
    return this.bulkCreateTeacher?.isRunning
  }

  @filterBy('records', 'matched', false)
  unmatchedRecords

  @equal('activeTab', 'unmatched')
  isUnmatchedTab

  @equal('activeTab', 'matched')
  isMatchedTab

  @notEmpty('unmatchedRecords')
  hasUnmatchedRecords

  @and('isUnmatchedTab', 'hasUnmatchedRecords')
  showCreateNew

  @filter('unmatchedRecords.@each.{isCreateBlakeTeacherChecked,matchError}', function (record) {
    return record.isCreateBlakeTeacherChecked && !record.matchError
  })
  unmatchedChecked

  @readOnly('unmatchedChecked.length')
  unmatchedCheckedCount

  @action
  bulkCreateAction() {
    const checkedRecords = this.unmatchedChecked
    const onComplete = () => {
      // Keep the modal open for a moment, to allow for button/success animation, before closing it
      if (this && !this.isDestroying && !this.isDestroyed) {
        set(this, 'showCreateUsersModal', false)
      }
    }
    this.bulkCreateTeacher.perform(checkedRecords, { onComplete, completeDelay: 1000 })
  }

  @action
  onSelectRow(cleverRecord, selected) {
    set(cleverRecord, 'isCreateBlakeTeacherChecked', selected)
  }

  @action
  showCreateUserModal() {
    set(this, 'showCreateUsersModal', true)
  }

  @action
  closeCreateUserModal() {
    const { submitState } = this
    if (submitState === 'load') return
    set(this, 'showCreateUsersModal', false)
  }

  @action
  onTextInput(value) {
    set(this, 'config.filtering.filterFieldValue', value)
  }
}
