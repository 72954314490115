import type Controller from '@ember/controller'
import Route from '@ember/routing/route'
import type SubscriptionTypeRoute from 'district-ui-client/subscription-type/route'

export default class SubscriptionTypeError404Route extends Route {
  setupController(controller: Controller, model: unknown) {
    const { subscriptionType } = this.modelFor('subscription-type') as ModelFor<SubscriptionTypeRoute>
    const errorController = controller
    errorController.set('subscriptionType', subscriptionType)
    super.setupController(errorController, model)
  }
}
