import { template as template_349b82ee9fa1498396214ff4382507b9 } from "@ember/template-compiler";
import type ReportingRexWorkingAtGradeLevelRoute from 'district-ui-client/reporting/ui-scope/rex/working-at-grade-level/route';
import type { TOC } from '@ember/component/template-only';
import { ChartsWorkingAtGradeLevelTotals } from 'district-ui-client/components/charts/working-at-grade-level/totals/component';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import fullGradeName from 'district-ui-client/helpers/full-grade-name';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<ReportingRexWorkingAtGradeLevelRoute>;
    };
}
export const RexWorkingAtGradeLevelRouteTemplate: TOC<Signature> = template_349b82ee9fa1498396214ff4382507b9(`
  <DetailedPageWrapper @empty={{not @model.workingAtGradeLevel}}>
    {{#each @model.workingAtGradeLevel.working_at_grade_level_totals_by_grade as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsWorkingAtGradeLevelTotals
          class="h-60"
          @data={{gradeData}}
          @product={{@model.product}}
          @grade={{fullGradeName gradeData.grade_position}}
          @tooltip={{t "reporting.tooltips.workingAtGradeLevel"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(RexWorkingAtGradeLevelRouteTemplate);
