import { template as template_fc6d6643f08040899912547b32e27155 } from "@ember/template-compiler";
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import orderBy from 'lodash/orderBy';
import type RouterService from '@ember/routing/router-service';
import type GradeSetsService from 'district-ui-client/services/grade-sets';
import type Student from 'district-ui-client/models/student';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import { on } from '@ember/modifier';
import { fn } from '@ember/helper';
import { t } from 'ember-intl';
import FaIcon from '@fortawesome/ember-fontawesome/components/fa-icon';
import { includes } from 'district-ui-client/helpers/includes';
import { UiButton } from 'district-ui-client/components/ui-button';
import colspanMax from 'district-ui-client/modifiers/colspan-max';
interface Args {
    students?: Student[];
    subscriptionType: SubscriptionType;
    selectedIds: string[];
    updateSelectedIds: (selectedIds: string[]) => void;
    isCleverDisabled?: boolean;
}
interface Signature {
    Args: Args;
    Blocks: {
        default: [];
    };
}
export class StudentTable extends Component<Signature> {
    @service
    router: RouterService;
    @service
    gradeSets: GradeSetsService;
    @tracked
    sortProperties = [
        'lastName:asc'
    ];
    get students() {
        return this.args.students ?? [];
    }
    get studentViewModels() {
        return this.args.students?.map((student)=>{
            const schoolClasses = student.schoolClassesFor(this.args.subscriptionType);
            const schoolClassNames = schoolClasses.length ? schoolClasses.map((sc)=>sc.name).sort().join(', ') : ' - ';
            const teachers = student.teachersFor(this.args.subscriptionType);
            const teacherNames = teachers.length ? teachers.map((t)=>t.fullNameReversed).sort().join('; ') : 'No Teacher';
            return {
                id: student.id,
                firstName: student.firstName,
                lastName: student.lastName,
                gradeName: this.gradeSets.nameFromGradeSetByPosition(student.gradePosition),
                schoolName: student.schoolName,
                schoolClassNames,
                teacherNames,
                login: student.login
            };
        });
    }
    get arrangedContent() {
        const properties = this.sortProperties.map((sortProperty)=>sortProperty.split(':')[0]);
        const directions = this.sortProperties.map((sortProperty)=>sortProperty.split(':')[1]).filter((property)=>property === 'asc' || property === 'desc');
        return orderBy(this.studentViewModels, properties, directions);
    }
    get isAllSelected() {
        return this.arrangedContent.every((t)=>this.args.selectedIds.includes(t.id));
    }
    sortBy = (property: string)=>{
        // Only supports 1 column, but can be extended.
        const [sortProperty, sortDirection] = this.sortProperties[0].split(':');
        if (sortProperty === property) {
            this.sortProperties = [
                `${property}:${sortDirection === 'asc' ? 'desc' : 'asc'}`
            ];
        } else {
            this.sortProperties = [
                `${property}:asc`
            ];
        }
    };
    onSelectAll = ()=>{
        if (this.isAllSelected) {
            this.args.updateSelectedIds?.([]);
        } else {
            this.args.updateSelectedIds?.(this.arrangedContent.map((t)=>t.id));
        }
    };
    onSelectStudent = (studentId: string)=>{
        if (this.args.selectedIds.includes(studentId)) {
            this.args.updateSelectedIds?.(this.args.selectedIds.filter((id)=>id !== studentId));
        } else {
            this.args.updateSelectedIds?.([
                ...this.args.selectedIds,
                studentId
            ]);
        }
    };
    goToEditStudent = (studentId: string)=>{
        this.router.transitionTo('subscription-type.manage.students.edit', studentId);
    };
    static{
        template_fc6d6643f08040899912547b32e27155(`
    <div>
      <div class="pb-3">
        {{yield}}
      </div>
      <div class="overflow-y-auto">
        <table data-test-student-table class="disco-table w-full bg-white">
          <thead>
            <tr>
              <th class="th-checkbox">
                <input
                  data-test-check-all
                  type="checkbox"
                  class="cursor-pointer"
                  checked={{this.isAllSelected}}
                  aria-label={{if this.isAllSelected (t "uncheckAll") (t "checkAll")}}
                  {{on "input" this.onSelectAll}}
                />
              </th>
              <th class="text-left" role="button" {{on "click" (fn this.sortBy "firstName")}}>
                {{t "manage.students.studentTable.headers.firstName"}}
                <FaIcon @icon="sort" @pull="right" />
              </th>
              <th class="text-left" role="button" {{on "click" (fn this.sortBy "lastName")}}>
                {{t "manage.students.studentTable.headers.lastName"}}
                <FaIcon @icon="sort" @pull="right" />
              </th>
              <th role="button" {{on "click" (fn this.sortBy "gradeName")}}>
                {{t "grade.label"}}
                <FaIcon @icon="sort" @pull="right" />
              </th>
              <th role="button" {{on "click" (fn this.sortBy "schoolClassNames")}}>
                {{t "manage.students.studentTable.headers.class"}}
                <FaIcon @icon="sort" @pull="right" />
              </th>
              <th role="button" {{on "click" (fn this.sortBy "teacherNames")}}>
                {{t "manage.students.studentTable.headers.teacher"}}
                <FaIcon @icon="sort" @pull="right" />
              </th>
              <th role="button" {{on "click" (fn this.sortBy "schoolName")}}>
                {{t "manage.students.studentTable.headers.schoolName"}}
                <FaIcon @icon="sort" @pull="right" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {{#each this.arrangedContent as |student index|}}
              <tr data-test-student-row={{index}}>
                <td align="center" class="td-checkbox">
                  <input
                    data-test-td-checkbox
                    type="checkbox"
                    class="cursor-pointer"
                    checked={{includes @selectedIds student.id}}
                    aria-label={{t
                      "manage.students.studentTable.rows.checkboxAriaLabel"
                      firstName=student.firstName
                      lastName=student.lastName
                    }}
                    {{on "input" (fn this.onSelectStudent student.id)}}
                  />
                </td>
                <td data-test-first-name align="left" class="break-all">
                  {{student.firstName}}
                </td>
                <td data-test-last-name align="left" class="break-all">
                  {{student.lastName}}
                </td>
                <td data-test-grade align="center">
                  {{student.gradeName}}
                </td>
                <td data-test-class-name align="center" class="td-class">
                  {{student.schoolClassNames}}
                </td>
                <td data-test-teacher-name align="center" class="td-teacher-name">
                  {{student.teacherNames}}
                </td>
                <td data-test-school-name align="center">
                  {{student.schoolName}}
                </td>
                <td class="td-edit" align="center">
                  <UiButton
                    class="regular ui-btn-small"
                    disabled={{@isCleverDisabled}}
                    aria-label="{{t 'components.studentTable.editStudent'}}"
                    {{on "click" (fn this.goToEditStudent student.id)}}
                    data-test-student-edit-link
                  >
                    <FaIcon @icon="pencil" />
                  </UiButton>
                </td>
              </tr>
            {{else}}
              <tr>
                <td {{colspanMax}}>
                  {{t "manage.students.studentTable.empty"}}
                </td>
              </tr>
            {{/each}}
          </tbody>
        </table>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default StudentTable;
