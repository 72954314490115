import { template as template_a07d35f4427e4c3692063fc3b5ebab34 } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
import { ChartsCourseProgressTotals } from 'district-ui-client/components/charts/course-progress/totals/component';
import type ReSpellingProgressTotalsRoute from 'district-ui-client/reporting/ui-scope/re/spelling-progress/totals/route';
import fullGradeName from 'district-ui-client/helpers/full-grade-name';
interface Signature {
    Args: {
        model: ModelFor<ReSpellingProgressTotalsRoute>;
    };
}
export const ReportingReSpellingProgressTotalsRouteTemplate: TOC<Signature> = template_a07d35f4427e4c3692063fc3b5ebab34(`
  <DetailedPageWrapper @empty={{not @model.spellingProgressTotals}}>
    {{#each @model.spellingProgressTotals.course_progress_totals as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsCourseProgressTotals
          class="h-full"
          @data={{gradeData}}
          @precinct={{@model.precinct}}
          @product={{@model.product}}
          @grade={{fullGradeName gradeData.grade_position}}
          @tooltip={{t "reporting.tooltips.spellingProgressTotals"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(ReportingReSpellingProgressTotalsRouteTemplate);
