import Controller from '@ember/controller'
import { service } from '@ember/service'
import { task } from 'ember-concurrency'

export default class CleverIdStartInitialSyncController extends Controller {
  @service clever

  @service intl

  @service flashQueue

  @service log

  @service router

  @service contentfulClient

  get cleverDistrictId() {
    const { cleverDistrictMatch } = this.model
    const cleverDistrictId = cleverDistrictMatch.cleverDistrictRelationId

    // In theory, this should not happen. A clever-district-match record should always have a clever-district-id even
    // before download.
    if (!cleverDistrictId) {
      throw Error('Clever district match relationShip does not have a cleverDistrict - id')
    }

    return cleverDistrictId
  }

  get isSyncTaskLoading() {
    return this.initialSyncTask.isRunning
  }

  initialSyncTask = task({ drop: true }, async () => {
    const { clever, intl, flashQueue, log, router, cleverDistrictId } = this
    const { subscriptionType } = this.model

    try {
      await clever.callInitialSync(cleverDistrictId, subscriptionType)
      flashQueue.addSuccess({ subtitle: intl.t('clever.flashMessages.district.success') })
      router.transitionTo('clever-id.initial-sync-in-progress')
    } catch (error) {
      if (String(error.status) === '422') {
        const subtitle = await this.productNotSharedErrorMsg(subscriptionType)
        flashQueue.addFail({ subtitle })
      } else {
        const errorMsg = intl.t('clever.flashMessages.district.fail')
        flashQueue.addFail({ subtitle: errorMsg })
        log.error(errorMsg, { backendError: await error.text() })
      }
    }
  })

  async productNotSharedErrorMsg(subscriptionType) {
    const guides = await this.contentfulClient.getCleverGuidePdfs()
    const helpGuideUrl = guides[subscriptionType] || ''

    const productName = this.intl.t(`subscriptions.${subscriptionType}`)
    const signupUrl = this.intl.t(`clever.signupUrl.${subscriptionType}`)
    const supportUrl = this.router.urlFor('subscription-type.support')
    return this.intl.t('clever.flashMessages.district.failNotShared', {
      htmlSafe: true,
      signupUrl,
      helpGuideUrl,
      productName,
      supportUrl,
    })
  }
}
