import { template as template_ad12765241434c3e9aec430814079e15 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { CleverUiModal } from 'district-ui-client/components/clever-ui/modal';
import { CleverUiConfirmDialog } from 'district-ui-client/components/clever-ui/confirm-dialog/component';
import { t } from 'ember-intl';
interface Signature {
    Args: {
        selectedCount: number;
        syncableSelectedCount: number;
        onClose: () => void;
        onSubmit: () => void;
    };
}
export class CleverModalsSyncConfirm extends Component<Signature> {
    get unsyncableSelectedCount() {
        return this.args.selectedCount - this.args.syncableSelectedCount;
    }
    static{
        template_ad12765241434c3e9aec430814079e15(`
    <CleverUiModal
      @isShowingModal={{true}}
      @onClose={{@onClose}}
      @title={{t
        (if
          this.unsyncableSelectedCount
          "components.cleverUi.modals.syncConfirmModal.unsyncTitle"
          "components.cleverUi.modals.syncConfirmModal.syncTitle"
        )
      }}
      @type={{if this.unsyncableSelectedCount "warning" "normal"}}
    >
      <CleverUiConfirmDialog
        data-test-sync-modal
        @negativeAction={{@onClose}}
        @positiveAction={{@onSubmit}}
        @negativeButtonText={{t "components.cleverUi.modals.syncConfirmModal.cancel"}}
        @positiveButtonText={{t "components.cleverUi.modals.syncConfirmModal.sync"}}
        @positiveButtonAppearance={{if this.unsyncableSelectedCount "orange" "regular"}}
      >
        <div>
          {{#if this.unsyncableSelectedCount}}
            <p class="mt-3 text-base">
              {{t
                "components.cleverUi.modals.syncConfirmModal.schoolUnsyncCount"
                count=this.unsyncableSelectedCount
                htmlSafe=true
              }}
            </p>
            <p class="text-base">{{t "components.cleverUi.modals.syncConfirmModal.body"}}</p>
          {{/if}}
          <p class="mb-0 mt-3 text-base">
            {{t
              "components.cleverUi.modals.syncConfirmModal.schoolSyncCount"
              count=@syncableSelectedCount
              htmlSafe=true
            }}
          </p>
        </div>
      </CleverUiConfirmDialog>
    </CleverUiModal>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default CleverModalsSyncConfirm;
