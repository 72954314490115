import EmberRouter from '@ember/routing/router'
import config from 'district-ui-client/config/environment'

export default class Router extends EmberRouter {
  location = config.locationType

  rootURL = config.rootURL
}

Router.map(function () {
  this.route('landing', { path: '/' })

  this.route('settings', function () {
    this.route('district-settings')
    this.route('district-coordinator')
  })

  this.route('subscription-type', { path: '/:subscription_type' }, function () {
    this.route('error404', { path: '/*path' })
    this.route('manage', function () {
      this.route('schools', function () {
        this.route('edit', { path: '/:id/edit' })
      })
      this.route('teachers', function () {
        this.route('edit', { path: '/:id/edit' })
        this.route('import-teachers')
        this.route('add-existing-teachers')
        this.route('new')
      })
      this.route('students', function () {
        this.route('edit', { path: '/:id/edit' })
        this.route('new')
        this.route('import-students')
      })
    })
    this.route('support')

    this.route('clever-id', { resetNamespace: true }, function () {
      this.route('initial-sync-in-progress')
      this.route('start-initial-sync')
      this.route('matching-in-progress')
    })

    this.route('clever', { resetNamespace: true }, function () {
      this.route('missing-fields')
      this.route('dashboard', function () {
        this.route('unsynced')
        this.route('synced')
      })
      this.route('match', function () {
        this.route('schools', { path: 'schools/:id' }, function () {
          this.route('teachers')
          this.route('students')
        })
      })
    })

    this.route('reporting', { resetNamespace: true }, function () {
      this.route('ui-scope', { path: '/:ui_scope_name/:ui_scope_id' }, function () {
        this.route('maths', { path: 'mathseeds' }, function () {
          this.route('standards-performance')
          this.route('lesson-performance')
          this.route('dashboard', function () {
            this.route('table')
          })
          this.route('summary', function () {
            this.route('table')
          })
          this.route('course-progress', function () {
            this.route('averages')
            this.route('totals')
          })
          this.route('driving-tests')
          this.route('time-on-task')
          this.route('quizzes-completed')
          this.route('working-at-grade-level')
          this.route('awards-earned')
          this.route('fluency-sprints', function () {
            this.route('table')
          })
        })

        this.route('re', { path: 'reading-eggs' }, function () {
          this.route('standards-performance')
          this.route('lesson-performance')
          this.route('dashboard', function () {
            this.route('table')
          })
          this.route('summary', function () {
            this.route('table')
          })
          this.route('course-progress', function () {
            this.route('totals')
            this.route('averages')
          })
          this.route('quizzes-completed')
          this.route('spelling-progress', function () {
            this.route('averages')
            this.route('totals')
          })
          this.route('lexile-details')
          this.route('working-at-grade-level')
          this.route('driving-tests')
          this.route('time-on-task')
          this.route('books-read')
          this.route('stories-written')
          this.route('awards-earned')
          this.route('storylands')
        })

        this.route('rex', { path: 'reading-eggspress' }, function () {
          this.route('dashboard', function () {
            this.route('table')
          })
          this.route('summary', function () {
            this.route('table')
          })
          this.route('course-progress', function () {
            this.route('totals')
            this.route('averages')
          })
          this.route('spelling-progress', function () {
            this.route('averages')
            this.route('totals')
          })
          this.route('lexile-details')
          this.route('working-at-grade-level')
          this.route('assessment-scores')
          this.route('quiz-scores')
          this.route('time-on-task')
          this.route('books-read', function () {
            this.route('by-level')
          })
          this.route('awards-earned')
        })

        this.route('fast-phonics', function () {
          this.route('standards-performance')
          this.route('lesson-performance')
        })
      })
    })
  })
})
