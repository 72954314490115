import { template as template_a5444ccc33f6440a8cf6d605d19777a7 } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import config from 'district-ui-client/config/environment';
import { join } from 'district-ui-client/utils/uri';
import { task } from 'ember-concurrency';
import type AuthToken from '@blakeelearning/auth/services/auth-token';
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type';
import { Panel } from 'district-ui-client/components/panel';
import themeKey from 'district-ui-client/helpers/theme-key';
import { t } from 'ember-intl';
import { Uploader } from 'district-ui-client/components/csv/uploader';
import FidgetLoadingIndicatorComponent from '@blakeelearning/fidget/components/fidget/loading-indicator';
import { UiButton } from 'district-ui-client/components/ui-button';
import { on } from '@ember/modifier';
import { isJsonApiErrorPayload, type JsonApiErrorPayload } from 'district-ui-client/errors/json-api-errors';
interface Signature {
    Args: {
        subscriptionType: SubscriptionType;
        successCommand: string;
        validateCommand: string;
        successMessage: string;
        afterSuccessAction?: () => void;
    };
    Element: HTMLDivElement;
}
export class ImportPanelTeacher extends Component<Signature> {
    @service
    authToken: AuthToken;
    @tracked
    errorMessages: Nullable<JsonApiErrorPayload> = null;
    @tracked
    warningMessages: Nullable<string[]> = null;
    @tracked
    uploadProgress = 0;
    @tracked
    hasSubmittedSuccessfully = false;
    get isUploading() {
        return this.uploadProgress > 0 && this.uploadProgress < 100;
    }
    get isUploadComplete() {
        return this.uploadProgress === 100;
    }
    get showUploadProgress() {
        return this.isUploading || this.isUploadComplete;
    }
    get isValid() {
        return this.isUploadComplete && isEmpty(this.errorMessages?.errors);
    }
    get isInvalid() {
        return this.isUploadComplete && !isEmpty(this.errorMessages?.errors);
    }
    get uri() {
        return join(config.readingEggsV1Url, 'district_ui', 'teacher_operations');
    }
    get isSubmitting() {
        return this.submitTask.isRunning;
    }
    get product() {
        return this.args.subscriptionType;
    }
    submitTask = task({
        drop: true
    }, async ()=>{
        const { uri, product } = this;
        const { successCommand } = this.args;
        const data = {
            command: successCommand,
            product
        };
        const response = await fetch(uri, {
            method: 'POST',
            headers: {
                Authorization: this.authToken.token ?? '',
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        if (!response.ok) throw await response.text();
        this.hasSubmittedSuccessfully = true;
        this.args.afterSuccessAction?.();
    });
    resetState = ()=>{
        this.uploadProgress = 0;
        this.errorMessages = null;
        this.warningMessages = null;
        this.hasSubmittedSuccessfully = false;
    };
    performSubmitTask = async ()=>{
        await this.submitTask.perform();
    };
    uploadProgressEvent = (progressEvent: ProgressEvent)=>{
        const { loaded, total } = progressEvent;
        const percentComplete = loaded && total ? Math.round((progressEvent.loaded / progressEvent.total) * 100) : 0;
        this.uploadProgress = Math.max(percentComplete, 99) // cap it at 99% until done event comes through
        ;
    };
    uploadStateChange = (xhr: XMLHttpRequest)=>{
        if (xhr.readyState === xhr.DONE) {
            this.uploadProgress = 100;
            if (xhr.status === 200) {
                const response = JSON.parse(xhr.responseText);
                this.warningMessages = response.warnings;
            } else if (xhr.status === 422) {
                const parsed = (this.errorMessages = JSON.parse(xhr.responseText));
                this.errorMessages = isJsonApiErrorPayload(parsed) ? parsed : null;
            }
        }
    };
    uploadAction = (file: File)=>{
        const { product, uri } = this;
        const { validateCommand } = this.args;
        this.resetState();
        const formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('product', product);
        formData.append('command', validateCommand);
        /**
     * As of July 2024, there still isn't a way to track upload progress with fetch, so this must be done with XHR.
     *
     * Providing a ReadableStream (with hooks to track progress) to the fetch request body is likely the way, but it is
     * not yet supported in Firefox or Safari.
     *
     * Additionally, our API as currently implemented requires it to be uploaded as FormData, with product & command,
     * which is an additional hurdle (FormData does not allow ReadableStream to be appended). So it would likely also
     * need an API change to upload to a dedicated endpoint (with product & command built in so that FormData is not
     * needed), or else a 3rd party package to support FormData+ReadableStream
     *
     * https://caniuse.com/mdn-api_request_request_request_body_readablestream
     * https://developer.mozilla.org/en-US/docs/Web/API/Request/body
     * https://stackoverflow.com/a/69400632
     */ const xhr = new XMLHttpRequest();
        xhr.addEventListener('readystatechange', ()=>this.uploadStateChange(xhr));
        xhr.upload.addEventListener('progress', this.uploadProgressEvent, false);
        xhr.open('POST', uri.href, true);
        xhr.setRequestHeader('Authorization', this.authToken.token ?? '');
        xhr.setRequestHeader('Accept', [
            'application/vnd.api+json'
        ].join(','));
        xhr.send(formData);
    };
    static{
        template_a5444ccc33f6440a8cf6d605d19777a7(`
    <div class="mx-auto md:w-full lg:w-1/2" ...attributes>
      <Panel
        data-test-import-panel
        @theme={{themeKey @subscriptionType}}
        @title={{t "fileImporter.headerTitle" uploadType="CSV"}}
      >
        <Uploader
          @uploadAction={{this.uploadAction}}
          @uploading={{this.isUploading}}
          @uploadComplete={{this.isUploadComplete}}
          @uploadProgress={{this.uploadProgress}}
        />
        {{#if this.isUploading}}
          <div class="mt-5">
            <FidgetLoadingIndicatorComponent
              @show={{true}}
              @overlay={{false}}
              @centered={{true}}
              @loadingText={{t "fileImporter.loadingText"}}
            />
          </div>
        {{else if this.isValid}}
          <div class="mt-5" data-test-valid-upload>
            {{#if this.hasSubmittedSuccessfully}}
              <div id="processing-message">
                <p class="mb-3">
                  {{@successMessage}}
                </p>
                <p>
                  {{t "fileImporter.successInfo"}}
                </p>
              </div>
            {{else}}
              {{#if this.warningMessages}}
                <p class="mb-3">
                  {{t "fileImporter.warningMessageTitle"}}
                </p>
                <ul data-test-upload-warnings class="mb-3">
                  {{#each this.warningMessages as |msg|}}
                    <li class="bg-juicy-orange-50 text-mid-orange-300 border-dusty-black-50 rounded border p-3">
                      {{msg}}
                    </li>
                  {{/each}}
                </ul>
              {{else}}
                <p class="mb-3">
                  {{t "fileImporter.noFileErrors"}}
                </p>
              {{/if}}
              <UiButton
                class="green"
                disabled={{this.isSubmitting}}
                {{on "click" this.performSubmitTask}}
                data-test-submit-button
              >
                {{#if this.isSubmitting}}
                  {{t "fileImporter.button.submitting"}}
                {{else}}
                  {{t "fileImporter.button.ready"}}
                {{/if}}
              </UiButton>
            {{/if}}
          </div>
        {{else if this.isInvalid}}
          <div class="mt-5" data-test-invalid-upload>
            <p class="mb-3">
              {{t "fileImporter.errorMessageTitle"}}
            </p>
            <ul data-test-upload-errors>
              {{#each this.errorMessages.errors as |msg|}}
                <li class="bg-watermelony-red-50 text-watermelony-red-350 border-dusty-black-50 rounded border p-3">
                  {{msg.detail}}
                </li>
              {{/each}}
            </ul>
          </div>
        {{/if}}
      </Panel>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
export default ImportPanelTeacher;
