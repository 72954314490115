import Model, { attr } from '@ember-data/model'
import type { SubscriptionType } from 'district-ui-client/domain/subscription-type'

/**
 * Model class for clever synchronisation at the district level
 *
 * This record will be included alongside a clever-district if it is undergoing clever setup for a specific subscription
 * type. As in, the user is in a state somewhere between having clicked the button to begin the clever process
 * (data download started) and having all the schools fully matched to blake records & synced up for that subscription
 * type.
 *
 * Despite the overloaded naming, the "Synchronised" flags are only relevant at the beginning of the process, indicating
 * the initial clever data download/automatching state. After that completes they're all true and the user can begin
 * actually confirming and matching data themselves.
 *
 * @class CleverSynchronisationModel
 * @property {string} subscriptionType - Type of subscription (maths / reading etc)
 * @property {Boolean} districtAdminsSynchronised - Whether the discos are synced
 * @property {Boolean} schoolAdminsSynchronised - Whether the subcos are synced
 * @property {Boolean} schoolsSynchronised - Whether the schools are synced
 * @property {Boolean} sectionsSynchronised - Whether the school classes are synced
 * @property {Boolean} teachersSynchronised - Whether the teachers are synced
 * @property {Boolean} studentsSynchronised - Whether the students are synced
 */

export default class Synchronisation extends Model {
  @attr('string') subscriptionType!: SubscriptionType

  @attr('boolean') districtAdminsSynchronised!: boolean

  @attr('boolean') schoolAdminsSynchronised!: boolean

  @attr('boolean') schoolsSynchronised!: boolean

  @attr('boolean') sectionsSynchronised!: boolean

  @attr('boolean') teachersSynchronised!: boolean

  @attr('boolean') studentsSynchronised!: boolean

  get isInitialized() {
    return (
      this.districtAdminsSynchronised &&
      this.schoolAdminsSynchronised &&
      this.schoolsSynchronised &&
      this.teachersSynchronised &&
      this.studentsSynchronised
    )
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'clever/synchronisation': Synchronisation
  }
}
