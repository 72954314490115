import { template as template_5236c1b4adc24009ac1363755595d02e } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
import type RexSpellingProgressAveragesRoute from 'district-ui-client/reporting/ui-scope/rex/spelling-progress/averages/route';
import { DetailedPageWrapper } from 'district-ui-client/components/reporting/detailed-page-wrapper';
import { ChartsCourseProgressAverages } from 'district-ui-client/components/charts/course-progress/averages/component';
import { not } from 'ember-truth-helpers';
import { t } from 'ember-intl';
import RouteTemplate from 'ember-route-template';
interface Signature {
    Args: {
        model: ModelFor<RexSpellingProgressAveragesRoute>;
    };
}
export const RexSpellingProgressAveragesRouteTemplate: TOC<Signature> = template_5236c1b4adc24009ac1363755595d02e(`
  <DetailedPageWrapper @empty={{not @model.spellingProgressAverages}}>
    {{#each @model.spellingProgressAverages.course_progress_averages as |gradeData|}}
      <div class="detailed-chart-wrapper">
        <ChartsCourseProgressAverages
          class="h-full"
          @data={{gradeData}}
          @precinct={{@model.precinct}}
          @product={{@model.product}}
          @tooltip={{t "reporting.tooltips.spellingProgressAverages"}}
          @title={{t "reporting.chartTitles.spellingProgressAverages"}}
        />
      </div>
    {{/each}}
  </DetailedPageWrapper>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RouteTemplate(RexSpellingProgressAveragesRouteTemplate);
