import Model, { hasMany, belongsTo, attr, type SyncHasMany } from '@ember-data/model'
import { dependentKeyCompat } from '@ember/object/compat'
import type CleverTeacherMatch from 'district-ui-client/models/clever/clever-teacher-match'
import type GravitySchool from 'district-ui-client/models/clever/school'
import type GravityStudent from 'district-ui-client/models/clever/student'

/**
 * Model class for teachers (gravity data)
 *
 * @class BlakeTeacherModel
 * @property {string} firstName - first name of the teacher
 * @property {string} lastName - last name of the teacher
 * @property {CleverTeacherMatchModel} cleverTeacherMatch - HasOne relationship to access the sisId
 * @property {string} sisId - An alias to the sisId from the blakeCleverTeacherMatch relationship
 * @property {string} fullName - Name like 'Klaus Dieter' or '-'
 */
export default class GravityTeacher extends Model {
  @attr('string') firstName!: string

  @attr('string') lastName!: string

  @attr('string') email!: string

  @attr('string') login!: string

  @attr('string') accountType!: string // used when creating blake teachers for matching

  @belongsTo('clever/clever-teacher-match', { async: false, inverse: 'teacher' })
  cleverTeacherMatch?: CleverTeacherMatch

  @hasMany('clever/student', { async: false, inverse: null })
  students?: SyncHasMany<GravityStudent>

  @belongsTo('clever/school', { async: false, inverse: 'teachers' })
  school?: GravitySchool

  get cleverTeacher() {
    return this.cleverTeacherMatch?.cleverTeacher
  }

  // Used in a computed property in match teachers table, in order to update dropdown options to remove teacher from
  // other dropdowns. Might be removable when that is using native getters.
  @dependentKeyCompat
  get sisId() {
    return this.cleverTeacherMatch?.sisId
  }

  get fullName() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ') || '-'
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'clever/teacher': GravityTeacher
  }
}
