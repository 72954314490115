import { template as template_693cb3936fb040e9a668648f101178aa } from "@ember/template-compiler";
import type { TOC } from '@ember/component/template-only';
interface Signature {
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
export const ModalDefaultBody: TOC<Signature> = template_693cb3936fb040e9a668648f101178aa(`
  <div class="relative overflow-y-auto p-3 text-center" ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalDefaultBody;
