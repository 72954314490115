import Service, { service } from '@ember/service'
import { timeout, task } from 'ember-concurrency'
import ENV from 'district-ui-client/config/environment'
import { join } from 'district-ui-client/utils/uri'
import type Store from '@ember-data/store'
import type AuthToken from '@blakeelearning/auth/services/auth-token'
import type RouterService from '@ember/routing/router-service'
import type CleverTeacher from 'district-ui-client/models/clever/clever-teacher'
import type { Log } from '@blakeelearning/log'
import type Transition from '@ember/routing/transition'

const second = 1000

export const ErrorCodes = {
  EXISTS_IN_DIFFERENT_DISTRICT: 'exists-in-different-district',
  EXISTS_AS_PARENT: 'exists-as-parent',
  EXISTS_AS_PARENT_CONTACT: 'exists-as-parent-contact',
  ID_MISMATCH_ERROR: 'id-mismatch-error',
  STALE_ACCOUNT_ERROR: 'stale-account-error',
  INVALID_EMAIL_CHAR: 'invalid-email-char',
} as const

export type ErrorCode = ValuesOf<typeof ErrorCodes>

export default class TeacherMatchErrorService extends Service {
  @service store!: Store

  @service authToken!: AuthToken

  @service router!: RouterService

  @service log!: Log

  _teacherMatchRemedyUrl(cleverSchoolId: string, cleverTeacherId: string) {
    return join(ENV.cleverV1Url, `clever-schools/${cleverSchoolId}/clever-teachers/${cleverTeacherId}/remedy`).href
  }

  /**
   * Makes a remote request to remedy the teacher match error.
   * { ok: true } if remedy successful.
   * { ok: false, error } if remedy unsuccessful.
   */

  teacherMatchErrorRemedy = async (
    cleverTeacher: CleverTeacher,
  ): Promise<{ ok: false; error: string } | { ok: true }> => {
    if (!cleverTeacher.cleverSchool) {
      return { ok: false, error: 'cleverTeacher.cleverSchool is undefined' }
    }
    const cleverSchoolId = cleverTeacher.cleverSchool.id
    const cleverTeacherId = cleverTeacher.id
    const url = this._teacherMatchRemedyUrl(cleverSchoolId, cleverTeacherId)

    const response = await fetch(url, {
      method: 'POST',
      headers: { Authorization: this.authToken.token ?? '', 'content-type': 'application/vnd.api+json' },
      body: JSON.stringify({ data: cleverTeacher.matchError }),
    })

    if (response.ok) return { ok: true }
    return { ok: false, error: await response.text() }
  }

  remedy = task(
    async (
      teacher: CleverTeacher,
      matchErrorCode: ErrorCode,
      {
        onComplete,
        onError,
      }: {
        onComplete?: (teacher: CleverTeacher, matchErrorCode: ErrorCode) => Promise<void>
        onError?: (teacher: CleverTeacher, matchErrorCode: ErrorCode, error: unknown) => void
      } = {},
    ): Promise<{ ok: false; error: unknown } | { ok: true }> => {
      try {
        const errorType = this.errorTypeForCode(matchErrorCode)
        if (errorType === undefined) {
          const errorMsg = `unhandled teacher match error code ${matchErrorCode}`
          this.log.error(errorMsg)
          throw Error(errorMsg)
        }
        this.assertTeacherHasErrorMetaProperties(teacher, errorType.code, errorType.metaProperties)
        if (errorType.remedy) {
          const result = await errorType.remedy(teacher)
          if (result.ok) {
            await onComplete?.(teacher, matchErrorCode)
            return { ok: true }
          } else {
            throw Error(`remedy function for ${matchErrorCode} unsuccessful: ${result.error}`)
          }
        } else {
          throw Error(`no remedy function defined for ${matchErrorCode}`)
        }
      } catch (error) {
        onError?.(teacher, matchErrorCode, error)
        return { ok: false, error }
      }
    },
  )

  assertTeacherHasErrorMetaProperties(teacher: CleverTeacher, matchErrorCode: ErrorCode, metaProperties?: string[]) {
    metaProperties?.forEach((metaProperty) => {
      if (teacher.matchError?.[metaProperty] === undefined) {
        throw Error(
          `expected clever/teacher with teacher match error code '${matchErrorCode}' to have matchError property ${metaProperty}, instead matchError contains: ${JSON.stringify(
            teacher.matchError || {},
          )}`,
        )
      }
    })
  }

  /**
   * `metaProperties` are the properties we expect to find in the clever/teacher json meta object,
   * these properties contain error context information used when performing the error remedy.
   * `remedy` is the function called to remedy the clever teacher match error.
   */
  get errorTypes(): {
    code: ErrorCode
    metaProperties?: string[]
    remedy?: (cleverTeacher: CleverTeacher) => Promise<{ ok: false; error: string } | { ok: true }>
  }[] {
    return [
      {
        code: ErrorCodes.EXISTS_IN_DIFFERENT_DISTRICT,
      },
      {
        code: ErrorCodes.EXISTS_AS_PARENT,
        remedy: this.teacherMatchErrorRemedy,
      },
      {
        code: ErrorCodes.EXISTS_AS_PARENT_CONTACT,
        remedy: this.teacherMatchErrorRemedy,
      },
      {
        code: ErrorCodes.ID_MISMATCH_ERROR,
        metaProperties: ['other-teacher-id'],
        remedy: this.teacherMatchErrorRemedy,
      },
      {
        code: ErrorCodes.STALE_ACCOUNT_ERROR,
        metaProperties: ['other-teacher-id'],
        remedy: this.teacherMatchErrorRemedy,
      },
      {
        code: ErrorCodes.INVALID_EMAIL_CHAR,
      },
    ]
  }

  errorTypeForCode(matchErrorCode: ErrorCode) {
    return this.errorTypes.find((errorType) => errorType.code === matchErrorCode)
  }

  isValidErrorCode(matchErrorCode: ErrorCode) {
    return this.errorTypeForCode(matchErrorCode) !== undefined
  }

  hasRemedyForCode(matchErrorCode: ErrorCode) {
    return typeof this.errorTypeForCode(matchErrorCode)?.remedy === 'function'
  }

  // Make sure to stub this in any tests that do remedies]
  refreshIntervals = [second, 4 * second, 5 * second, 10 * second, 10 * second, 10 * second, 10 * second, 10 * second]

  /*
   * Will reload the school & teachers to ensure school sync state and all matches are up to date post-remedy.
   *
   * It will poll those records for about a minute at reduced intervals. Ideally this wouldn't be necessary, and it
   * could stop when the match error code is removed/changed on the teacher record, but unfortunately this error code
   * can be resolved before the remedy has actually finished. There isn't (currently) a way on the frontend to know when
   * a remedy starts or finishes.
   */
  postRemedyRefresh = task({ restartable: true }, async (teacher, matchErrorCode, refreshAction) => {
    const { refreshIntervals } = this

    // Kill it if user decides to leave whatever page this was started from.
    const { currentRouteName } = this.router
    const cancelRefresh = (transition: Transition) => {
      // ensure transition is more than a query param transition
      if (currentRouteName !== transition?.to?.name) {
        this.router.off('routeDidChange', cancelRefresh)
        void this.postRemedyRefresh.cancelAll()
      }
    }

    try {
      this.router.on('routeDidChange', cancelRefresh)

      // Now poll for a while, backoff over time
      for (const interval of refreshIntervals) {
        await timeout(interval)
        await refreshAction()
      }
    } finally {
      this.router.off('routeDidChange', cancelRefresh)
    }
  })
}
