import { service } from '@ember/service'
import RSVP from 'rsvp'
import Route from '@ember/routing/route'
import type StudentEventsService from 'district-ui-client/services/student-events'
import type UiScopeRoute from 'district-ui-client/reporting/ui-scope/route'
import type { StatsScope } from 'district-ui-client/utils/student-events/stats-scope'
import type { NormalizedInterval } from 'date-fns'
import type { CourseProgressAveragesData } from 'district-ui-client/components/charts/course-progress/averages/component'

interface StatsResponse {
  courseProgressAverages: Nullable<{ course_progress_averages: CourseProgressAveragesData[] }>
}

export default class ReCourseProgressAveragesRoute extends Route {
  @service studentEvents!: StudentEventsService

  async model() {
    const product: LegacyProductRe = 're'
    const precinct: LegacyPrecinctReading = 'reading'

    const {
      uiScope: scope,
      statsScope,
      period,
      scopedData,
    } = this.modelFor('reporting.ui-scope') as ModelFor<UiScopeRoute>

    const stats = await this.makeStatsRequest(product, precinct, statsScope, period)
    return {
      product,
      scope,
      period,
      scopedData,
      precinct,
      ...stats,
    }
  }

  makeStatsRequest(
    product: string,
    precinct: string,
    scope: StatsScope,
    period: NormalizedInterval,
  ): Promise<StatsResponse> {
    const { studentEvents } = this

    return RSVP.hash({
      courseProgressAverages: studentEvents.courseProgressAverages(product, precinct, scope, period),
    })
  }
}
