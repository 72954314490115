import Model, { attr, belongsTo } from '@ember-data/model'
import type Store from '@ember-data/store'
import { service } from '@ember/service'
import type District from 'district-ui-client/models/district'
import type { CleverDistrict } from 'district-ui-client/models/clever/clever-district'
import { SubscriptionType } from 'district-ui-client/domain/subscription-type'
import type AuthToken from '@blakeelearning/auth/services/auth-token'

/**
 * Model class for matching record between clever and blake districts.
 *
 * This record is created in the backend when a clever-id is defined for a district in admin console. It does not
 * necessarily mean there is a clever-district yet - that is done as part of the "clever download" process.
 *
 * You can assume that if this record exists, a clever-id exists (a sha-like string, which is also used for the clever-district.id)
 *
 * @class CleverDistrictMatchModel
 * @property {String} mdrNumber - marketing id
 * @property {Boolean} readingCleverManaged - bool to indicate if the district is clever managed
 * @property {Boolean} readingCleverShared - bool to indicate if the district clever download process has begun
 * @property {Boolean} mathsCleverManaged - bool to indicate if the district is clever managed
 * @property {Boolean} mathsCleverShared - bool to indicate if the district clever download process has begun
 */
export default class CleverDistrictMatch extends Model {
  @service store!: Store

  @service authToken!: AuthToken

  @attr('string') mdrNumber!: string

  @attr('boolean') readingCleverShared!: boolean

  @attr('boolean') readingCleverManaged!: boolean

  @attr('boolean') mathsCleverShared!: boolean

  @attr('boolean') mathsCleverManaged!: boolean

  @belongsTo('district', { async: false, inverse: 'cleverDistrictMatch' })
  district!: District

  /**
   * Having no clever-district attached to the match record means they haven't started the "clever download" yet
   */
  @belongsTo('clever/clever-district', { async: false, inverse: 'cleverDistrictMatch' })
  cleverDistrict?: CleverDistrict

  /**
   * While there may not be a clever-district attached to this record yet, it should always have a clever-district-id
   * provided by the relationships key when the match record is in the payload.
   *
   * This id matches the one that is entered into admin.
   */
  get cleverDistrictRelationId() {
    // @ts-expect-error "Argument of type 'string' is not assignable to parameter of type 'Exclude<keyof this, keyof Model>'".
    return this.belongsTo('cleverDistrict').id()
  }

  /**
   * Loads clever-district record. Note that it is possible for this to return a 404 - meaning the clever download has
   * not finished yet.
   */
  async loadCleverDistrict({ ignore404 = false } = {}): Promise<CleverDistrict | null> {
    // do this request with fetch (rather than ED) to avoid the 404 corrupting the store, we need to keep the id cached
    const adapter = this.store.adapterFor('clever/clever-district')
    const url = adapter.buildURL('clever/clever-district', this.cleverDistrictRelationId, null, 'findRecord')
    const response = await fetch(url, { headers: { Authorization: this.authToken.token ?? '' } })
    if (response.ok) {
      const payload = await response.json()
      this.store.pushPayload('clever/clever-district', payload)
      return this.store.peekRecord('clever/clever-district', this.cleverDistrictRelationId)
    }

    if (response.status === 404 && ignore404) return null

    throw await response.text()
  }

  /**
   * To reload a match record, we reload the district with the match record included.
   */
  async reloadMatch() {
    return this.store.findRecord('district', this.district.id, {
      reload: true,
      include: 'clever-district-match',
    })
  }

  getCleverSharedByType(subscriptionType: SubscriptionType) {
    switch (subscriptionType) {
      case SubscriptionType.Reading:
        return this.readingCleverShared
      case SubscriptionType.Maths:
        return this.mathsCleverShared
      default:
        return false
    }
  }

  getCleverManagedByType(subscriptionType: SubscriptionType) {
    switch (subscriptionType) {
      case SubscriptionType.Reading:
        return this.readingCleverManaged
      case SubscriptionType.Maths:
        return this.mathsCleverManaged
      default:
        return false
    }
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'clever/clever-district-match': CleverDistrictMatch
  }
}
